import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { modalRegistered } from "../../../shared/components/modal/ModalService";
import { toastService } from "../../../shared/components/toast/toastService";

// Define the initial state using that type

export interface modalSliceProps{
    name: string;
    show: boolean;
}

const setInitialState = () : modalSliceProps[] =>{
  return Object.keys(modalRegistered).map(key => {
    var item : modalSliceProps = {
      name: key,
      show: false
    }
  
    return item;
  });
  
}

const initialState: modalSliceProps[] = setInitialState();

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    ShowModal: (state, action: PayloadAction<string>) => {
      if (action.payload !== null && action.payload) {
        for (let index = 0; index < state.length; index++) {
            const element = state[index];
            if(element.name.includes(action.payload))
                element.show = true;
            else
              console.error("Modales: El modal que intentas abrir no esta registrado");
        }
      }
    },
    HideModal: (state, action: PayloadAction<string>) => {
        if (action.payload !== null && action.payload) {
            for (let index = 0; index < state.length; index++) {
                const element = state[index];
                if(element.name === action.payload)
                    element.show = false;
            }
          }
      }
  },
});
export const { ShowModal, HideModal } = modalSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const ModalSelector = (state: RootState) => state.modalSlice;
export default modalSlice.reducer;
