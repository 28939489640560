import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { GearTypeContract } from "../../../services/engranajeTipo/Contracts";
import { RootState } from "../../store";
// Define the initial state using that type
const initialState: GearTypeContract = {
    id: 0,
    name: "",
    dientes: [],
    description: ""
};
export const currentGearSlice = createSlice({
  name: "currentGear",
  initialState,
  reducers: {
    setCurrentGear: (state, action: PayloadAction<GearTypeContract | undefined>) => {
        if(action.payload !== null && action.payload)
        {
            state.dientes = action.payload.dientes;
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.description = action.payload.description;
        }
    }
  },
});
export const { setCurrentGear } = currentGearSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const getCurrentGearSelector = (state: RootState) => state.currentGearSlice;
export default currentGearSlice.reducer;