import ApiService from "../../apiService/api";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { SolicitudEmpresaRequestDto, SolicitudEmpresaResponseDto } from "./Contract";

const urlBase = "solicitudempresa";
const endpoints = {
    Crear: 'Crear'
}

const Crear = async (data: SolicitudEmpresaRequestDto) : Promise<BaseResponse<number>| undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.Crear;
    var response = await ApiService.post<number>(finalUrl, null, data)
    return response;
}

const GetUrlComplete = {
    Crear: `${urlBase}/${endpoints.Crear}`.toLowerCase()
}

export const SolicitudEmpresaService = {
    Crear,
    GetUrlComplete
}