import {
  Pagination,
  PaginationItem,
  PaginationRenderItemParams,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../shared/store/hooks";
import { currentQuestion } from "../../../shared/store/slices/question/currentQuestionSlice";


//Se agrega una pagina adicional para agregar la seccion de obervaciones.

const QuestionPagination = ({ onChangePage, isBienestar }: QuestionPaginationProps) => {
  const [additionalPage, setAdditionalPage] = useState<number>(1);
  const getCurrentQuestion = useAppSelector(currentQuestion);
  const [pageSize, setPageSize] = useState<number>(getCurrentQuestion.paginasTotales + additionalPage);
  const [currentPage, setCurrentPage] = useState<number>(getCurrentQuestion.paginaActual);

  const handleSetPaginationConfig = (item: PaginationRenderItemParams) => {
    const isAllQuestionResolved: boolean =
      getCurrentQuestion.preguntasResueltas.length ===
      getCurrentQuestion.paginasTotales;

    const isPageResolved =
      item.type === "page" &&
      getCurrentQuestion.preguntasResueltas.includes(item.page!);
    const background: string = isPageResolved
      ? "green"
      : isAllQuestionResolved
      ? "green"
      : "#5022ff";
    const colorText: string =
      isPageResolved && item.selected ? "green" : "white";

    return (
      <PaginationItem
        {...item}
        component="div"
        onClick={() => handleSetOptionsarar(item.page!)}
        sx={{
          backgroundColor: background,
          color: colorText,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&::after": {
            content: isPageResolved ? '"✔"' : '""',
          },
        }}
      >
        ✔
      </PaginationItem>
    );
  };

  const handleSetOptionsarar = (page: number) => {
    const size = getCurrentQuestion.paginasTotales + additionalPage;

    if (size === page) {
      setCurrentPage(page);
    } else {
    }
    onChangePage(page);


    setPageSize(size);
  };

  useEffect(() =>{
    const add = isBienestar ? 0 : 1;
    setAdditionalPage(add)
    setPageSize(getCurrentQuestion.paginasTotales + add)
  },[isBienestar])

  return (
    <Pagination
      className="flex justify-center"
      count={pageSize}
      page={currentPage}
      shape="rounded"
      renderItem={handleSetPaginationConfig}
    />
  );
};

export default QuestionPagination;

interface QuestionPaginationProps {
  onChangePage: (value: number) => void;
  isBienestar: boolean
}
