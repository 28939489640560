import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoginButton from "../../shared/components/loginButton/LoginButton";
import { messagesErrorToast } from "../../shared/components/toast/messagesErrorToast";
import { toastService } from "../../shared/components/toast/toastService";
import Footer from "../../shared/layout/footer/Footer";
import { CantidadEmpleadoService } from "../../shared/services/cantidadEmpleados/CantidadEmpleadoService";
import { GetEmpresaInfoContractResponse } from "../../shared/services/cantidadEmpleados/Contract";
import { OrganizationTypeService } from "../../shared/services/organizacionTipo/OrganizationTypeService";
import { SolicitudEmpresaRequestDto } from "../../shared/services/solicitudEmpresa/Contract";
import { SolicitudEmpresaService } from "../../shared/services/solicitudEmpresa/SolicitudEmpresaService";
import { useAppSelector } from "../../shared/store/hooks";
import { connectionPendings } from "../../shared/store/slices/loadingConnectionSlice";
import Navbar from "../home/navbar/Navbar";
import ApplyForm, { ApplyFormValues } from "./components/ApplyForm";



const Apply = () => {
  const isLoadingconnection = useAppSelector(connectionPendings);
  const {isAuthenticated} = useAuth0();
  const [empresaData, setEmpresaData] = useState<GetEmpresaInfoContractResponse[] | undefined>([]); // Define the state with the correct type
  const [tipoOrganizacion, setTipoOrganizacion] = useState<GetEmpresaInfoContractResponse[] | undefined>([]);

  const testpetition = async () => {
    var response = await CantidadEmpleadoService.GetEmpresaInfo();
    if (response?.data) {
      setEmpresaData(response?.data); // Assuming response.data is an array
    }
  };

  const getTipoOrganizacion = async () => {
    var response = await OrganizationTypeService.GetTipoOrganizacion();

    if(response?.data){
      setTipoOrganizacion(response?.data);
    }
  }

  const handleSubmit = async (value: ApplyFormValues) =>{

    const data : SolicitudEmpresaRequestDto ={
      CantidadEmpleadosId: Number(value.employeesCant),
      Cargo: value.position,
      Email: value.email,
      Nombre: value.fullname,
      Rubro: value.industry,
      Telefono: value.phone,
      TipoOrganizacionId: Number(value.organizationType)
    };

    await SolicitudEmpresaService.Crear(data).then(_e =>{
      toastService.success(messagesErrorToast.APPLY_SUCCESS);
    });



  }

  useEffect(() => {
    testpetition();
    getTipoOrganizacion();
  }, []);

  return (
    <>
    <Navbar withColor />
    <div
      className="relative z-10 overflow-hidden  pt-[120px] dark:bg-dark md:pt-[130px] lg:pt-[160px]"
    >
      
      <div className="container">
        <div className="flex flex-wrap items-center -mx-4">
          <div className="w-full px-4">
            <div className="text-center">
              <h1
                className="mb-4 text-3xl font-bold text-dark dark:text-white sm:text-4xl md:text-[40px] md:leading-[1.2]"
              >
                ¡Aplica ahora!

              </h1>
              <p className="mb-5  text-body-color dark:text-dark-6 text-lg">
              Utiliza nuestra herramienta de diagnóstico digital para obtener una visión clara de las fortalezas y 
              debilidades estructurales de tu empresa u organización. Para empezar, contáctanos rellenando el formulario a continuación.               
              </p>

              {isAuthenticated ?
              <p>
                Ya iniciaste sesión. Puedes acceder al panel desde aquí <Link className="text-lg text-blue-600" to="/audit">Control Panel</Link>
              </p>
              
              :
              <p>
                  Por otro lado, si ya tienes una cuenta <LoginButton
                  loginText="inicia sesión para acceder al proceso de auditoría."
                  className="inline text-blue-600"
                />
              </p>
            }            

            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="bg-[#F4F7FF] py-14 lg:py-[90px] dark:bg-dark mt-14">
      <div className="container">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <div
              className="wow fadeInUp relative mx-auto max-w-[525px] overflow-hidden rounded-xl shadow-form bg-white dark:bg-dark-2 py-14 px-8 text-center sm:px-12 md:px-[60px]"
              data-wow-delay=".15s"
            >
              <div className="mb-10 text-center">
                <p
                  className="mx-auto inline-block max-w-[160px] text-4xl"
                >
                  AUDITRIX
                </p>
              </div>
              <div className="flex justify-center items-center bg-gray-100">
                    <ApplyForm 
                    onSubmit={(value) => handleSubmit(value)}
                    companyInfo={empresaData} 
                    organizationType={tipoOrganizacion}/>
              </div>

              <p className="text-base text-body-secondary">
                Ya tienes una cuenta?
                <LoginButton
                  loginText="Inicia sesión."
                  className="mb-5 ms-1 text-lg text-blue-600"
                />
              </p>

              <div>
                <span className="absolute top-1 right-1">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="1.39737"
                      cy="38.6026"
                      r="1.39737"
                      transform="rotate(-90 1.39737 38.6026)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.39737"
                      cy="1.99122"
                      r="1.39737"
                      transform="rotate(-90 1.39737 1.99122)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.6943"
                      cy="38.6026"
                      r="1.39737"
                      transform="rotate(-90 13.6943 38.6026)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.6943"
                      cy="1.99122"
                      r="1.39737"
                      transform="rotate(-90 13.6943 1.99122)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="25.9911"
                      cy="38.6026"
                      r="1.39737"
                      transform="rotate(-90 25.9911 38.6026)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="25.9911"
                      cy="1.99122"
                      r="1.39737"
                      transform="rotate(-90 25.9911 1.99122)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.288"
                      cy="38.6026"
                      r="1.39737"
                      transform="rotate(-90 38.288 38.6026)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.288"
                      cy="1.99122"
                      r="1.39737"
                      transform="rotate(-90 38.288 1.99122)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.39737"
                      cy="26.3057"
                      r="1.39737"
                      transform="rotate(-90 1.39737 26.3057)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.6943"
                      cy="26.3057"
                      r="1.39737"
                      transform="rotate(-90 13.6943 26.3057)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="25.9911"
                      cy="26.3057"
                      r="1.39737"
                      transform="rotate(-90 25.9911 26.3057)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.288"
                      cy="26.3057"
                      r="1.39737"
                      transform="rotate(-90 38.288 26.3057)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.39737"
                      cy="14.0086"
                      r="1.39737"
                      transform="rotate(-90 1.39737 14.0086)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.6943"
                      cy="14.0086"
                      r="1.39737"
                      transform="rotate(-90 13.6943 14.0086)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="25.9911"
                      cy="14.0086"
                      r="1.39737"
                      transform="rotate(-90 25.9911 14.0086)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.288"
                      cy="14.0086"
                      r="1.39737"
                      transform="rotate(-90 38.288 14.0086)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
                <span className="absolute left-1 bottom-1">
                  <svg
                    width="29"
                    height="40"
                    viewBox="0 0 29 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="2.288"
                      cy="25.9912"
                      r="1.39737"
                      transform="rotate(-90 2.288 25.9912)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="14.5849"
                      cy="25.9911"
                      r="1.39737"
                      transform="rotate(-90 14.5849 25.9911)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.7216"
                      cy="25.9911"
                      r="1.39737"
                      transform="rotate(-90 26.7216 25.9911)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="2.288"
                      cy="13.6944"
                      r="1.39737"
                      transform="rotate(-90 2.288 13.6944)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="14.5849"
                      cy="13.6943"
                      r="1.39737"
                      transform="rotate(-90 14.5849 13.6943)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.7216"
                      cy="13.6943"
                      r="1.39737"
                      transform="rotate(-90 26.7216 13.6943)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="2.288"
                      cy="38.0087"
                      r="1.39737"
                      transform="rotate(-90 2.288 38.0087)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="2.288"
                      cy="1.39739"
                      r="1.39737"
                      transform="rotate(-90 2.288 1.39739)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="14.5849"
                      cy="38.0089"
                      r="1.39737"
                      transform="rotate(-90 14.5849 38.0089)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.7216"
                      cy="38.0089"
                      r="1.39737"
                      transform="rotate(-90 26.7216 38.0089)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="14.5849"
                      cy="1.39761"
                      r="1.39737"
                      transform="rotate(-90 14.5849 1.39761)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.7216"
                      cy="1.39761"
                      r="1.39737"
                      transform="rotate(-90 26.7216 1.39761)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
    </>
  );
};

export default Apply;
