import {
  Pagination,
  PaginationItem,
  PaginationItemProps,
  PaginationRenderItemParams,
} from "@mui/material";
import { GetQuestionByTeethIdContract } from "../../../shared/services/question/Contracts";
import Checkbox from "../../../shared/components/Inputs/Checkbox";
import { useAppSelector } from "../../../shared/store/hooks";
import { connectionPendings } from "../../../shared/store/slices/loadingConnectionSlice";
import { ReactElement, useEffect, useState } from "react";
import { TeethResolveService } from "../../../shared/services/dienteResultado/TeethResolveService";
import SimpleLoading from "../../../shared/IconSvg/Spinner/SimpleLoading";
import { QuestionService } from "../../../shared/services/question/QuestionService";
import QuestionPagination from "./QuestionPagination";
import QuestionObservation from "./QuestionObservation";
import { getCurrentGearSelector } from "../../../shared/store/slices/gear/currentGearSlice";
import { Common } from "../../../shared/common";

const POSITION_NOTAPPLY = 5;

const QuestionList = (props: QuestionListProps) => {
  const lastPage = props.currentQuestion.paginasTotales;
  const [resolveQuestionLoading, setResolveQuestionLoading] =
    useState<boolean>(false);
  const [hasLastPage, setHasLastPage] = useState<boolean>(false);
  const [answerClicked, setAnswerClicked] = useState<number>();
  const [showMessage, setShowMessage] = useState<ReactElement>();
  const loadingConnection = useAppSelector(connectionPendings);
  const [isBienestar, setIsBientestar] = useState<boolean>(false);
  const currentGearSelector = useAppSelector(getCurrentGearSelector);


  const handleChangePage = (page: number) =>{
    var isLastPage = (lastPage+1) === page && !isBienestar ? true : false
    
    setHasLastPage(isLastPage);

    if(!isLastPage)
      props.onChangePage(page)

  }

  const handleClearAnswerClicked = (loading: boolean) => {
    if (!loading) {
      setShowMessage(
        <p className="ml-3 bg-green-600 p-1 rounded-sm text-white animate-bounce">
          Guardado
        </p>
      );

      setTimeout(() => {
        setShowMessage(undefined);
        setAnswerClicked(undefined);
      }, 1000);
    }
  };

  useEffect(() => {
    var loadingTeeth = loadingConnection.includes(
      TeethResolveService.GetUrlComplete.ResolverPregunta
    );
    var loadingQuestion = loadingConnection.includes(
      QuestionService.GetUrlComplete.GetQuestionByTeethId
    );
    var isLoading = loadingTeeth || loadingQuestion;

    setResolveQuestionLoading(isLoading);
    handleClearAnswerClicked(isLoading);
  }, [loadingConnection]);

  useEffect(() => {
    let isBienestar: boolean = currentGearSelector.name === Common.GearNames.Bienestar;
    console.log(isBienestar,"osea hello")
    setIsBientestar(isBienestar);
  }, [props.currentQuestion])


  return (
    <div>
      <div>

        {hasLastPage 
          ?
        
          <QuestionObservation/>
          
          :
          <>
            <h3 className="text-xl mb-2">
              {props.currentQuestion.contenido ?? "No hay contenido"}
            </h3>
            <div>

              {props.currentQuestion.respuestas?.map((respuesta, index) => (
                <div key={respuesta.id} className="flex items-center">
                  {resolveQuestionLoading && answerClicked === respuesta.id ? (
                    <div className="p-2">
                      <SimpleLoading />
                    </div>
                  ) : (
                    <Checkbox
                      id={`respuesta-${respuesta.id}`}
                      name="respuestas"
                      checked={respuesta.respondida}
                      onChange={(e) => {
                        if (!answerClicked && !resolveQuestionLoading) {
                          setAnswerClicked(respuesta.id);

                          if(index === POSITION_NOTAPPLY)
                            props.onResolveAnswer(respuesta.id, true);
                          else
                            props.onResolveAnswer(respuesta.id, false);
                        }
                      }}
                      className="w-[1.5rem] h-[1.5rem]"
                    />
                  )}

                  {/* ${!resolveQuestionLoading && answerClicked === respuesta.id && 'bg-green-600 p-1 rounded-sm animate-bounce text-white'} */}
                  <label
                    htmlFor={`respuesta-${respuesta.id}`}
                    className={`ml-2 cursor-pointer `}
                  >
                    {respuesta.contenido}
                  </label>
                  {answerClicked === respuesta.id && showMessage}
                </div>
              ))}  
            </div>
          </>
        }
      </div>

      <QuestionPagination
        onChangePage={(value) => handleChangePage(value)}
        isBienestar={isBienestar}
      />
    </div>
  );
};

interface QuestionListProps {
  currentQuestion: GetQuestionByTeethIdContract;
  onChangePage: (page: number) => void;
  onResolveAnswer: (answer: number, notApply: boolean) => void;
}

export default QuestionList;
