import ApiService from "../../apiService/api";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { SolicitudUsuarioRequestDto } from "./Contract";

const urlBase = "solicitudusuario";
const endpoints = {
    Crear: 'Crear'
}

const Crear = async (data: SolicitudUsuarioRequestDto) : Promise<BaseResponse<number>| undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.Crear;
    var response = await ApiService.post<number>(finalUrl, null, data)
    return response;
}

const GetUrlComplete = {
    Crear: `${urlBase}/${endpoints.Crear}`.toLowerCase()
}

export const SolicitudUsuarioService = {
    Crear,
    GetUrlComplete
}