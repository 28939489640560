import { useAuth0 } from "@auth0/auth0-react";
import InviteCompany from "./features/InviteCompany/InviteCompany";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import ScrollToTop from "./features/home/ScrollToTop";
import NotFound from "./features/notFound/NotFound";
import { AuthenticationService } from "./shared/authentication/AuthenticationService";
import PageTitle from "./shared/components/pageTitle/PageTitle";
import { toastTypes } from "./shared/components/toast/toastService";
import routerConfig from "./shared/routeConfig/routeConfig";
import { useAppSelector } from "./shared/store/hooks";
import { ShowToastSelector } from "./shared/store/slices/showToastSlice";


function App() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const showToast = useAppSelector(ShowToastSelector);
  
  const notify = () => {
    
    if(showToast.content){
      switch (showToast.type) {

        case toastTypes.error:
          toast.error(showToast.content, showToast.options);
          break;
        
        case toastTypes.info:
          toast.info(showToast.content, showToast.options);
          break

        case toastTypes.success:
          toast.success(showToast.content, showToast.options);
          break

        case toastTypes.warning:
          toast.warning(showToast.content, showToast.options);
          break
      }
    }

  }
  
  //Obtencion y guardado de token.
  const saveToken = async () => {
    var token = await getAccessTokenSilently();
    await AuthenticationService.saveAuthentication(token);
  };

  useEffect(() => {
    if (isAuthenticated) {
      saveToken()
    }
  }, [user?.sub]);
  
  useEffect(() =>{
    notify();
  },[showToast])

  return (
    <div>
    <ScrollToTop />

      <Routes>
        {routerConfig.map((e) =>
          e.componentLoading ? (
              <Route
                key={e.path}
                element={<PageTitle children={e.component} title={e.title}/>}
                path={e.path}
                index={e.index}
              />
          ) : (
            <Route
              key={e.path}
              element={<PageTitle children={e.component} title={e.title}/>}
              path={e.path}
              index={e.index}
            />
          )
        )}
      <Route path="*" element={<NotFound/>}/>
      </Routes>

    </div>
  );
}

export default App;
