import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FaqAccordion from "../../shared/components/accordeon/FaqAccordion";
import SimpleCard from "../../shared/components/card/SimpleCard";
import { toastService } from "../../shared/components/toast/toastService";
import Footer from "../../shared/layout/footer/Footer";
import Hero from "../../shared/layout/hero/Hero";
import { SolicitudUsuarioRequestDto } from "../../shared/services/solicitudUsuario/Contract";
import { SolicitudUsuarioService } from "../../shared/services/solicitudUsuario/SolicitudUsuarioService";
import HomeForm, { HomeFormValues } from "./components/HomeForm";
import "./Home.module.css";
import Navbar from "./navbar/Navbar";
import { Common } from "../../shared/common";

const Home: React.FC = () => {
  const { id } = useParams();
  const [codigo, setCodigo] = useState<string>();

  const handleSubmit = async (value: HomeFormValues) => {
    const data: SolicitudUsuarioRequestDto = {
      Email: value.email,
      Nombre: value.name,
      Apellido: value.lastName,
      Telefono: value.phone,
      Mensaje: value.message,
    };

    await SolicitudUsuarioService.Crear(data).then((_e) => {
      toastService.success("Nos comunicaremos contigo en la brevedad.");
    });
  };

  useEffect(() => {
    if (id) {
      const scrollToElement = () => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };
      setTimeout(scrollToElement, 300); // Ajusta el tiempo si es necesario
    } else if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      const scrollToElement = () => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };
      setTimeout(scrollToElement, 300); // Ajusta el tiempo si es necesario
    }
  }, [id]);

  useEffect(() => {
    setCodigo(Common.EncryptNumber("10"));
  }, []);
  return (
    <div>
      <Navbar withColor={false} />
      <Hero />

      {/* NOSOTROS */}
      <section
        id="about"
        className="bg-gray-1 pb-8 pt-20 dark:bg-dark-2 lg:pb-[70px] lg:pt-[120px]"
      >
        <div className="container">
          <div className="wow fadeInUp" data-wow-delay=".2s">
            <div className="-mx-4 flex flex-wrap items-center">
              <div className="w-full px-4 lg:w-1/2">
                <div className="mb-12 max-w-[540px] lg:mb-0">
                  <h2 className="mb-5 text-3xl font-bold leading-tight text-dark dark:text-white sm:text-[40px] sm:leading-[1.2] text-start">
                    ¿Qué es Auditrix?
                  </h2>
                  <p className="mb-3 text-base leading-relaxed text-body-color dark:text-dark-6 text-start">
                  Es una herramienta de diagnóstico digital que permite medir las fortalezas y debilidades estructurales de una organización.
                  </p>

                  <p className=" text-body-color dark:text-dark-6 text-start">
                  Está diseñada a partir de múltiples disciplinas como dinámica sistémica, ingeniería, sistemas de gestión, administración de empresas, marketing, calidad total, liderazgo, comunicación y un importante cúmulo de experiencias con las que se configuran las preguntas generativas para testear la integridad de todo el sistema: capital humano y variables operativas de la organización.
                  </p>

                  <h2
                    id="about"
                    className="my-5 text-3xl font-bold leading-tight text-dark dark:text-white sm:text-[40px] sm:leading-[1.2] text-start"
                  >
                    ¿Quienes Somos?
                  </h2>

                  <p className="mb-3 text-base leading-relaxed text-body-color dark:text-dark-6 text-start">
                  Somos un equipo de apoyo entrenado para el desarrollo integral de tu organización.
                  </p>

                  <p className=" text-body-color dark:text-dark-6 text-start">
                  Nuestra propuesta de valor está compuesta por la <span className="font-semibold">tecnología</span> que integra todo el conocimiento disponible para monitorear la performance de la organización y nuestros <span className="font-semibold">servicios</span> para optimizar acciones congruentes de mejora continua.
                  </p>
                </div>
              </div>

              <div className="w-full px-4 lg:w-1/2">
                <div className="-mx-2 flex flex-wrap sm:-mx-4 lg:-mx-2 xl:-mx-4">
                  <div className="w-full px-2 sm:w-1/2 sm:px-4 lg:px-2 xl:px-4">
                    <div className="mb-4 sm:mb-8 sm:h-[400px] md:h-[540px] lg:h-[400px] xl:h-[500px]">
                      <img
                        src="../assets/images/about/about-image-01.jpg"
                        alt="about"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  </div>

                  <div className="w-full px-2 sm:w-1/2 sm:px-4 lg:px-2 xl:px-4">
                    <div className="mb-4 sm:mb-8 sm:h-[220px] md:h-[346px] lg:mb-4 lg:h-[225px] xl:mb-8 xl:h-[310px]">
                      <img
                        src="./assets/images/about/about-image-02.jpg"
                        alt="about"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>

                    <div className="relative z-10 mb-4 flex items-center justify-center overflow-hidden bg-primary px-6 py-12 sm:mb-8 sm:h-[160px] sm:p-5 lg:mb-4 xl:mb-8">
                      <div>
                        {/* <span className="block text-5xl font-extrabold text-white">
                          20
                        </span>
                        <span className="block text-base font-semibold text-white">
                          Años
                        </span>
                        <span className="block text-base font-medium text-white text-opacity-70">
                          De experiencia
                        </span> */}
                      </div>
                      <div>
                        <span className="absolute left-0 top-0 -z-10">
                          <svg
                            width="106"
                            height="144"
                            viewBox="0 0 106 144"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.1"
                              x="-67"
                              y="47.127"
                              width="113.378"
                              height="131.304"
                              transform="rotate(-42.8643 -67 47.127)"
                              fill="url(#paint0_linear_1416_214)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1416_214"
                                x1="-10.3111"
                                y1="47.127"
                                x2="-10.3111"
                                y2="178.431"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="white" />
                                <stop
                                  offset="1"
                                  stopColor="white"
                                  stopOpacity="0"
                                />
                              </linearGradient>
                            </defs>
                          </svg>
                        </span>
                        <span className="absolute right-0 top-0 -z-10">
                          <svg
                            width="130"
                            height="97"
                            viewBox="0 0 130 97"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.1"
                              x="0.86792"
                              y="-6.67725"
                              width="155.563"
                              height="140.614"
                              transform="rotate(-42.8643 0.86792 -6.67725)"
                              fill="url(#paint0_linear_1416_215)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1416_215"
                                x1="78.6495"
                                y1="-6.67725"
                                x2="78.6495"
                                y2="133.937"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="white" />
                                <stop
                                  offset="1"
                                  stopColor="white"
                                  stopOpacity="0"
                                />
                              </linearGradient>
                            </defs>
                          </svg>
                        </span>
                        <span className="absolute bottom-0 right-0 -z-10">
                          <svg
                            width="175"
                            height="104"
                            viewBox="0 0 175 104"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.1"
                              x="175.011"
                              y="108.611"
                              width="101.246"
                              height="148.179"
                              transform="rotate(137.136 175.011 108.611)"
                              fill="url(#paint0_linear_1416_216)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1416_216"
                                x1="225.634"
                                y1="108.611"
                                x2="225.634"
                                y2="256.79"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="white" />
                                <stop
                                  offset="1"
                                  stopColor="white"
                                  stopOpacity="0"
                                />
                              </linearGradient>
                            </defs>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* BENEFICIOS */}
      <section
        className="relative z-10 overflow-hidden bg-primary py-20 lg:py-[115px]"
        id="beneficios"
      >
        <div className="container mx-auto">
          <div className="relative overflow-hidden">
            <div className="-mx-4 flex flex-wrap items-stretch">
              <div className="w-full px-4">
                <div className="mx-auto max-w-[570px] text-center">
                  <h2 className="mb-8 text-3xl font-bold text-white md:text-[38px] md:leading-[1.44]">
                    <span className="text-white">
                      La aplicación de Auditrix promueve:
                    </span>
                  </h2>
                  <p className="mx-auto mb-6 max-w-[515px] text-base leading-[1.5] text-white text-start">
                    <span className="font-bold text-2xl text-white">
                      Organizaciones prósperas
                    </span>{" "}
                    al aumentar su eficiencia, competitividad, asertividad,
                    dinamismo y creatividad para generar nuevas dimensiones o
                    verticales de negocios.
                  </p>
                  <p className="mx-auto mb-6 max-w-[515px] text-base leading-[1.5] text-white text-start">
                    <span className="font-bold text-2xl text-white">
                      Organizaciones inteligentes
                    </span>{" "}
                    porque impulsa conversaciones con más aprendizajes y
                    habilidades que potencian un ciclo permanente de mejora
                    continua y sistémica.
                  </p>
                  <p className="mx-auto mb-6 max-w-[515px] text-base leading-[1.5] text-white text-start">
                    <span className="font-bold text-2xl text-white">
                      Organizaciones conscientes
                    </span>{" "}
                    del valor y cualidades de su capital humano, de su
                    responsabilidad social y su relación con el medio ambiente.
                  </p>

                  <Link to="/apply" target="_blank" className="z-50">
                    <p className="inline-block rounded-md border border-transparent bg-secondary px-7 py-3 text-base font-medium text-white transition hover:bg-[#0BB489] z-50">
                      Aplica ahora!
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="z-0">
          <span className="absolute left-0 top-0 z-0 pointer-events-none">
            <svg
              width="495"
              height="470"
              viewBox="0 0 495 470"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="55"
                cy="442"
                r="138"
                stroke="white"
                strokeOpacity="0.04"
                strokeWidth="50"
              />
              <circle
                cx="446"
                r="39"
                stroke="white"
                strokeOpacity="0.04"
                strokeWidth="20"
              />
              <path
                d="M245.406 137.609L233.985 94.9852L276.609 106.406L245.406 137.609Z"
                stroke="white"
                strokeOpacity="0.08"
                strokeWidth="12"
              />
            </svg>
          </span>
          <span className="absolute bottom-0 right-0 z-0 pointer-events-none">
            <svg
              width="493"
              height="470"
              viewBox="0 0 493 470"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="462"
                cy="5"
                r="138"
                stroke="white"
                strokeOpacity="0.04"
                strokeWidth="50"
              />
              <circle
                cx="49"
                cy="470"
                r="39"
                stroke="white"
                strokeOpacity="0.04"
                strokeWidth="20"
              />
              <path
                d="M222.393 226.701L272.808 213.192L259.299 263.607L222.393 226.701Z"
                stroke="white"
                strokeOpacity="0.06"
                strokeWidth="13"
              />
            </svg>
          </span>
        </div>
      </section>

      {/* USUARIOS */}
      <section
        id="usuarios"
        className="relative z-20 overflow-hidden pb-12 bg-[#E9F9FF] pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]"
      >
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[510px] text-center">
                <span className="mb-2 block text-lg font-semibold text-primary">
                  Nuestros usuarios
                </span>
                <h2 className="mb-3 text-3xl font-bold text-dark dark:text-white sm:text-4xl md:text-[40px] md:leading-[1.2]">
                  ¿Para quién es Auditrix?
                </h2>
                <p className="text-base text-body-color dark:text-dark-6">
                El modelo es útil para emprendedores, empresarios y funcionarios decididos a que su organización de un salto de calidad hacia la excelencia e integridad sistémica.

                </p>
              </div>
            </div>
          </div>
          <div className="-mx-4 flex flex-wrap justify-center">
            <SimpleCard
              title="Micropyme"
              imageSrc="./assets/images/ceo.jpg"
              defaultDescription="Ver Detalles"
              hoverDescription="Iniciar un proyecto o administrar un sistema pequeño es un gran desafío que requiere testeo y calibración de variables vitales para su despegue y desarrollo."
            />

            <SimpleCard
              title="Pyme"
              imageSrc="./assets/images/result.jpg"
              defaultDescription="Ver Detalles"
              hoverDescription="En una Pyme no hay tiempo para el palabrerío complicado, aquí la eficiencia y competitividad son las bases fundamentales para funcionar bien y crecer. Para eso conviene aplicar una herramienta de diagnóstico simple, rápido y con datos reales que muestre un plan de acción con prioridades."
            />

            <SimpleCard
              title="Empresa"
              imageSrc="./assets/images/teamwork.jpg"
              defaultDescription="Ver Detalles"
              hoverDescription="Contar con un mecanismo de auditorías inteligentes permite 
                              monitorear todos los sistemas y subsistemas de la empresa. 
                              Ayudando a auditores, gerentes y mandos medios a tomar decisiones 
                              congruentes y efectivas."
            />

            <SimpleCard
              title="Institución"
              imageSrc="./assets/images/planing.jpg"
              defaultDescription="Ver Detalles"
              hoverDescription="Cuando un organismo público se ocupa de la integridad de las personas y la integridad de sus interacciones, no solo alcanza buenos niveles de eficiencia sino que además, contribuye de manera directa al bienestar de la sociedad a la cual se debe."
            />
          </div>
          <div className="mx-auto flex justify-center">
            <Link to="/apply" target="_blank">
              <p className="inline-block rounded-md border border-transparent bg-secondary px-7 py-3 text-base font-medium text-white transition hover:bg-[#0BB489]">
                Aplica ahora!
              </p>
            </Link>
          </div>
        </div>
      </section>

      <section className="py-20 lg:py-[115px] px-16  " id="function">
        <h1 className="text-4xl font-bold text-center mb-12">
          Cómo iniciar el camino hacia la mejora continua
        </h1>

        <div className="flex flex-col lg:flex-row justify-center items-start lg:space-x-8 space-y-8 lg:space-y-0">
          <Link to="/#usuarios">
            <div className="step flex flex-col items-center flex-1">
              <div className="w-12 h-12 flex items-center justify-center bg-blue-700 text-white rounded-full mb-4 shadow-lg transform hover:scale-110 transition-transform duration-300">
                1
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md w-full hover:bg-blue-50 transition-colors duration-300">
                <h2 className="text-xl font-semibold text-blue-700 mb-2">
                  Versión
                </h2>
                <p className="text-gray-700">
                  Elige nivel de auditoría según tipo de organización.
                </p>
              </div>
            </div>
          </Link>

          <Link to="/apply">
            <div className="step flex flex-col items-center flex-1">
              <div className="w-12 h-12 flex items-center justify-center bg-blue-700 text-white rounded-full mb-4 shadow-lg transform hover:scale-110 transition-transform duration-300">
                2
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md w-full hover:bg-blue-50 transition-colors duration-300">
                <h2 className="text-xl font-semibold text-blue-700 mb-2">
                  Acceso
                </h2>
                <p className="text-gray-700">
                  Crea tu cuenta e Inicia sesión para acceder al módulo Auditoría Sistémica.
                </p>
              </div>
            </div>
          </Link>

          <Link to="/#servicios">
            <div className="step flex flex-col items-center flex-1">
              <div className="w-12 h-12 flex items-center justify-center bg-blue-700 text-white rounded-full mb-4 shadow-lg transform hover:scale-110 transition-transform duration-300">
                3
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md w-full hover:bg-blue-50 transition-colors duration-300">
                <h2 className="text-xl font-semibold text-blue-700 mb-2">
                  Reporte & Plan
                </h2>
                <p className="text-gray-700">
                  Recibe reporte gráfico de resultados y plan de acciones sugeridas.
                </p>
              </div>
            </div>
          </Link>

          <Link to="/#footer">
            <div className="step flex flex-col items-center flex-1">
              <div className="w-12 h-12 flex items-center justify-center bg-blue-700 text-white rounded-full mb-4 shadow-lg transform hover:scale-110 transition-transform duration-300">
                4
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md w-full hover:bg-blue-50 transition-colors duration-300">
                <h2 className="text-xl font-semibold text-blue-700 mb-2 text-no-wrap">
                  Mejora Continua
                </h2>
                <p className="text-gray-700">
                  Solicita servicio para generar modelo de mejora continua.
                </p>
              </div>
            </div>
          </Link>
        </div>
      </section>

      {/* <section className="relative bg-[#E9F9FF] pb-8 pt-20 dark:bg-dark lg:pb-[50px] lg:pt-[120px] px-6 lg:px-20">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-center lg:text-left mb-12 text-dark dark:text-white">
            Servicios a medida para potenciar la eficiencia y competitividad de
            tu organización
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {[
              { title: "Sistemas de calidad (TQM)" },
              { title: "Auditorias de sistemas" },
              { title: "Reingeniería organizacional" },
              { title: "Optimización de procesos" },
              { title: "Liderazgo" },
              { title: "Negociación" },
              { title: "Marketing estratégico" },
              { title: "Comunicación efectiva" },
              { title: "Atención al cliente" },
              { title: "Formulación y gestión de proyectos" },
              { title: "Desarrollo personal" },
              { title: "Soluciones informáticas" },
              { title: "Soporte tecnológico" },
              { title: "Interfases IA" },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-[#cee8f5] dark:bg-gray-800 shadow-lg rounded-lg p-6 flex flex-col items-center text-center"
              >
                <h4 className="text-xl font-bold text-dark dark:text-white mb-2">
                  {item.title}
                </h4>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* FAQ */}
      <section
        className="relative z-20 overflow-hidden bg-[#E9F9FF] pb-8 pt-20 dark:bg-dark lg:pb-[50px] lg:pt-[120px]"
        id="faq"
      >
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[520px] text-center">
                <span className="mb-2 block text-lg font-semibold text-primary">
                  Preguntas Frecuentes
                </span>
                <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
                  ¿Tienes Preguntas? Aquí Tienes Respuestas
                </h2>
                <p className="mx-auto max-w-[485px] text-base text-body-color dark:text-dark-6">
                  Explora nuestras respuestas a las preguntas más comunes sobre
                  nuestros servicios de auditoría. Si no encuentras lo que
                  buscas, no dudes en contactarnos.
                </p>
              </div>
            </div>
          </div>

          <div className="-mx-2 flex flex-wrap">
            <div className="w-full md:w-1/2 px-2 py-3">
              <FaqAccordion
                question="Qué es la integridad sistémica de una organización?"
                answer="Es la coherencia, consistencia y alineación de todas sus variables sistémicas. Implica una gestión integral, efectiva y ética de todas las áreas para alcanzar objetivos estratégicos y desarrollo humano."
              />
            </div>
            <div className="w-full md:w-1/2 px-2 py-3">
              <FaqAccordion
                question="Qué son las variables sistémicas?"
                answer="Son las variables de cuya interacción se define un sistema. Están relacionadas con la organización (tangibles) y con el capital humano (intangibles). Todas se testean con preguntas generativas para detectar su grado de integridad sistémica."
              />
            </div>

            <div className="w-full md:w-1/2 px-2 py-3">
              <FaqAccordion
                question="Por qué debería aplicar Auditrix en mi organización?"
                answer="Porque una auditoría sistémica permite ver en perspectiva cuáles son las debilidades estructurales que frenan el desarrollo de tu organización, para luego definir acciones congruentes con las demandas de mayor eficiencia y competitividad en tu sector."
              />
            </div>
            <div className="w-full md:w-1/2 px-2 py-3">
              <FaqAccordion
                question="Es para todo tipo de organizaciones?"
                answer="Si es para todo tipo de organizaciones, públicas o privadas de cualquier tipo y tamaño, ya que Auditrix cuenta con la tecnología para aprender y customizar el modelo de diagnóstico."
              />
            </div>

            <div className="w-full md:w-1/2 px-2 py-3">
              <FaqAccordion
                question="Qué costo tiene aplicar Auditrix?"
                answer="Luego de enviar el formulario para Aplicar, y según las características o necesidades de la organización, se cotiza el plan de suscripción y/o programa de servicios."
              />
            </div>
            <div className="w-full md:w-1/2 px-2 py-3">
              <FaqAccordion
                question="Quién hace la auditoría?"
                answer="La auditoría digital es realizada por todos los que fueron definidos como usuarios comprometidos con la mejora del sistema. Y las auditorias asistidas son realizadas por miembros de nuestro equipo, lo cual se acuerda luego de enviar el formulario para Aplicar."
              />
            </div>

            <div className="w-full md:w-1/2 px-2 py-3">
              <FaqAccordion
                question="Qué pasa después de Aplicar?"
                answer="Una vez recibido tu formulario nos ponemos en contacto, acordamos propuesta y damos acceso a la Cuenta Principal del módulo: Auditoría Sistémica. Desde allí podrás realizar tu auditoría e invitar a usuarios de tu organización a crear su cuenta e iniciar sesión para hacer su propia auditoría. Luego cada usuario verá sus Resultados Individuales y solo la Cuenta Principal podrá ver los Resultados Globales de la organización."
              />
            </div>
            <div className="w-full md:w-1/2 px-2 py-3">
              <FaqAccordion
                question="Cuándo puedo contratar los servicios?"
                answer="Cuando gustes organizamos agenda, todos nuestros servicios están disponibles. No obstante, siempre es recomendable una auditoría previa para dirigir acciones congruentes con las debilidades que manifieste actualmente tu sistema."
              />
            </div>
          </div>
        </div>
        <div>
          <span className="absolute left-4 top-4 -z-[1]">
            <svg
              width="48"
              height="134"
              viewBox="0 0 48 134"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="45.6673"
                cy="132"
                r="1.66667"
                transform="rotate(180 45.6673 132)"
                fill="#13C296"
              />
              <circle
                cx="45.6673"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 45.6673 117.333)"
                fill="#13C296"
              />
              <circle
                cx="45.6673"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 45.6673 102.667)"
                fill="#13C296"
              />
              <circle
                cx="45.6673"
                cy="88.0001"
                r="1.66667"
                transform="rotate(180 45.6673 88.0001)"
                fill="#13C296"
              />
              <circle
                cx="45.6673"
                cy="73.3335"
                r="1.66667"
                transform="rotate(180 45.6673 73.3335)"
                fill="#13C296"
              />
              <circle
                cx="45.6673"
                cy="45.0001"
                r="1.66667"
                transform="rotate(180 45.6673 45.0001)"
                fill="#13C296"
              />
              <circle
                cx="45.6673"
                cy="16.0001"
                r="1.66667"
                transform="rotate(180 45.6673 16.0001)"
                fill="#13C296"
              />
              <circle
                cx="45.6673"
                cy="59.0001"
                r="1.66667"
                transform="rotate(180 45.6673 59.0001)"
                fill="#13C296"
              />
              <circle
                cx="45.6673"
                cy="30.6668"
                r="1.66667"
                transform="rotate(180 45.6673 30.6668)"
                fill="#13C296"
              />
              <circle
                cx="45.6673"
                cy="1.66683"
                r="1.66667"
                transform="rotate(180 45.6673 1.66683)"
                fill="#13C296"
              />
              <circle
                cx="31.0013"
                cy="132"
                r="1.66667"
                transform="rotate(180 31.0013 132)"
                fill="#13C296"
              />
              <circle
                cx="31.0013"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 31.0013 117.333)"
                fill="#13C296"
              />
              <circle
                cx="31.0013"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 31.0013 102.667)"
                fill="#13C296"
              />
              <circle
                cx="31.0013"
                cy="88.0001"
                r="1.66667"
                transform="rotate(180 31.0013 88.0001)"
                fill="#13C296"
              />
              <circle
                cx="31.0013"
                cy="73.3335"
                r="1.66667"
                transform="rotate(180 31.0013 73.3335)"
                fill="#13C296"
              />
              <circle
                cx="31.0013"
                cy="45.0001"
                r="1.66667"
                transform="rotate(180 31.0013 45.0001)"
                fill="#13C296"
              />
              <circle
                cx="31.0013"
                cy="16.0001"
                r="1.66667"
                transform="rotate(180 31.0013 16.0001)"
                fill="#13C296"
              />
              <circle
                cx="31.0013"
                cy="59.0001"
                r="1.66667"
                transform="rotate(180 31.0013 59.0001)"
                fill="#13C296"
              />
              <circle
                cx="31.0013"
                cy="30.6668"
                r="1.66667"
                transform="rotate(180 31.0013 30.6668)"
                fill="#13C296"
              />
              <circle
                cx="31.0013"
                cy="1.66683"
                r="1.66667"
                transform="rotate(180 31.0013 1.66683)"
                fill="#13C296"
              />
              <circle
                cx="16.3333"
                cy="132"
                r="1.66667"
                transform="rotate(180 16.3333 132)"
                fill="#13C296"
              />
              <circle
                cx="16.3333"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 16.3333 117.333)"
                fill="#13C296"
              />
              <circle
                cx="16.3333"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 16.3333 102.667)"
                fill="#13C296"
              />
              <circle
                cx="16.3333"
                cy="88.0001"
                r="1.66667"
                transform="rotate(180 16.3333 88.0001)"
                fill="#13C296"
              />
              <circle
                cx="16.3333"
                cy="73.3335"
                r="1.66667"
                transform="rotate(180 16.3333 73.3335)"
                fill="#13C296"
              />
              <circle
                cx="16.3333"
                cy="45.0001"
                r="1.66667"
                transform="rotate(180 16.3333 45.0001)"
                fill="#13C296"
              />
              <circle
                cx="16.3333"
                cy="16.0001"
                r="1.66667"
                transform="rotate(180 16.3333 16.0001)"
                fill="#13C296"
              />
              <circle
                cx="16.3333"
                cy="59.0001"
                r="1.66667"
                transform="rotate(180 16.3333 59.0001)"
                fill="#13C296"
              />
              <circle
                cx="16.3333"
                cy="30.6668"
                r="1.66667"
                transform="rotate(180 16.3333 30.6668)"
                fill="#13C296"
              />
              <circle
                cx="16.3333"
                cy="1.66683"
                r="1.66667"
                transform="rotate(180 16.3333 1.66683)"
                fill="#13C296"
              />
              <circle
                cx="1.66732"
                cy="132"
                r="1.66667"
                transform="rotate(180 1.66732 132)"
                fill="#13C296"
              />
              <circle
                cx="1.66732"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 1.66732 117.333)"
                fill="#13C296"
              />
              <circle
                cx="1.66732"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 1.66732 102.667)"
                fill="#13C296"
              />
              <circle
                cx="1.66732"
                cy="88.0001"
                r="1.66667"
                transform="rotate(180 1.66732 88.0001)"
                fill="#13C296"
              />
              <circle
                cx="1.66732"
                cy="73.3335"
                r="1.66667"
                transform="rotate(180 1.66732 73.3335)"
                fill="#13C296"
              />
              <circle
                cx="1.66732"
                cy="45.0001"
                r="1.66667"
                transform="rotate(180 1.66732 45.0001)"
                fill="#13C296"
              />
              <circle
                cx="1.66732"
                cy="16.0001"
                r="1.66667"
                transform="rotate(180 1.66732 16.0001)"
                fill="#13C296"
              />
              <circle
                cx="1.66732"
                cy="59.0001"
                r="1.66667"
                transform="rotate(180 1.66732 59.0001)"
                fill="#13C296"
              />
              <circle
                cx="1.66732"
                cy="30.6668"
                r="1.66667"
                transform="rotate(180 1.66732 30.6668)"
                fill="#13C296"
              />
              <circle
                cx="1.66732"
                cy="1.66683"
                r="1.66667"
                transform="rotate(180 1.66732 1.66683)"
                fill="#13C296"
              />
            </svg>
          </span>
          <span className="absolute bottom-4 right-4 -z-[1]">
            <svg
              width="48"
              height="134"
              viewBox="0 0 48 134"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="45.6673"
                cy="132"
                r="1.66667"
                transform="rotate(180 45.6673 132)"
                fill="#3758F9"
              />
              <circle
                cx="45.6673"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 45.6673 117.333)"
                fill="#3758F9"
              />
              <circle
                cx="45.6673"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 45.6673 102.667)"
                fill="#3758F9"
              />
              <circle
                cx="45.6673"
                cy="88.0001"
                r="1.66667"
                transform="rotate(180 45.6673 88.0001)"
                fill="#3758F9"
              />
              <circle
                cx="45.6673"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 45.6673 73.3333)"
                fill="#3758F9"
              />
              <circle
                cx="45.6673"
                cy="45.0001"
                r="1.66667"
                transform="rotate(180 45.6673 45.0001)"
                fill="#3758F9"
              />
              <circle
                cx="45.6673"
                cy="16.0001"
                r="1.66667"
                transform="rotate(180 45.6673 16.0001)"
                fill="#3758F9"
              />
              <circle
                cx="45.6673"
                cy="59.0001"
                r="1.66667"
                transform="rotate(180 45.6673 59.0001)"
                fill="#3758F9"
              />
              <circle
                cx="45.6673"
                cy="30.6668"
                r="1.66667"
                transform="rotate(180 45.6673 30.6668)"
                fill="#3758F9"
              />
              <circle
                cx="45.6673"
                cy="1.66683"
                r="1.66667"
                transform="rotate(180 45.6673 1.66683)"
                fill="#3758F9"
              />
              <circle
                cx="31.0006"
                cy="132"
                r="1.66667"
                transform="rotate(180 31.0006 132)"
                fill="#3758F9"
              />
              <circle
                cx="31.0006"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 31.0006 117.333)"
                fill="#3758F9"
              />
              <circle
                cx="31.0006"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 31.0006 102.667)"
                fill="#3758F9"
              />
              <circle
                cx="31.0006"
                cy="88.0001"
                r="1.66667"
                transform="rotate(180 31.0006 88.0001)"
                fill="#3758F9"
              />
              <circle
                cx="31.0008"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 31.0008 73.3333)"
                fill="#3758F9"
              />
              <circle
                cx="31.0008"
                cy="45.0001"
                r="1.66667"
                transform="rotate(180 31.0008 45.0001)"
                fill="#3758F9"
              />
              <circle
                cx="31.0008"
                cy="16.0001"
                r="1.66667"
                transform="rotate(180 31.0008 16.0001)"
                fill="#3758F9"
              />
              <circle
                cx="31.0008"
                cy="59.0001"
                r="1.66667"
                transform="rotate(180 31.0008 59.0001)"
                fill="#3758F9"
              />
              <circle
                cx="31.0008"
                cy="30.6668"
                r="1.66667"
                transform="rotate(180 31.0008 30.6668)"
                fill="#3758F9"
              />
              <circle
                cx="31.0008"
                cy="1.66683"
                r="1.66667"
                transform="rotate(180 31.0008 1.66683)"
                fill="#3758F9"
              />
              <circle
                cx="16.3341"
                cy="132"
                r="1.66667"
                transform="rotate(180 16.3341 132)"
                fill="#3758F9"
              />
              <circle
                cx="16.3341"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 16.3341 117.333)"
                fill="#3758F9"
              />
              <circle
                cx="16.3341"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 16.3341 102.667)"
                fill="#3758F9"
              />
              <circle
                cx="16.3341"
                cy="88.0001"
                r="1.66667"
                transform="rotate(180 16.3341 88.0001)"
                fill="#3758F9"
              />
              <circle
                cx="16.3338"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 16.3338 73.3333)"
                fill="#3758F9"
              />
              <circle
                cx="16.3338"
                cy="45.0001"
                r="1.66667"
                transform="rotate(180 16.3338 45.0001)"
                fill="#3758F9"
              />
              <circle
                cx="16.3338"
                cy="16.0001"
                r="1.66667"
                transform="rotate(180 16.3338 16.0001)"
                fill="#3758F9"
              />
              <circle
                cx="16.3338"
                cy="59.0001"
                r="1.66667"
                transform="rotate(180 16.3338 59.0001)"
                fill="#3758F9"
              />
              <circle
                cx="16.3338"
                cy="30.6668"
                r="1.66667"
                transform="rotate(180 16.3338 30.6668)"
                fill="#3758F9"
              />
              <circle
                cx="16.3338"
                cy="1.66683"
                r="1.66667"
                transform="rotate(180 16.3338 1.66683)"
                fill="#3758F9"
              />
              <circle
                cx="1.66732"
                cy="132"
                r="1.66667"
                transform="rotate(180 1.66732 132)"
                fill="#3758F9"
              />
              <circle
                cx="1.66732"
                cy="117.333"
                r="1.66667"
                transform="rotate(180 1.66732 117.333)"
                fill="#3758F9"
              />
              <circle
                cx="1.66732"
                cy="102.667"
                r="1.66667"
                transform="rotate(180 1.66732 102.667)"
                fill="#3758F9"
              />
              <circle
                cx="1.66732"
                cy="88.0001"
                r="1.66667"
                transform="rotate(180 1.66732 88.0001)"
                fill="#3758F9"
              />
              <circle
                cx="1.66732"
                cy="73.3333"
                r="1.66667"
                transform="rotate(180 1.66732 73.3333)"
                fill="#3758F9"
              />
              <circle
                cx="1.66732"
                cy="45.0001"
                r="1.66667"
                transform="rotate(180 1.66732 45.0001)"
                fill="#3758F9"
              />
              <circle
                cx="1.66732"
                cy="16.0001"
                r="1.66667"
                transform="rotate(180 1.66732 16.0001)"
                fill="#3758F9"
              />
              <circle
                cx="1.66732"
                cy="59.0001"
                r="1.66667"
                transform="rotate(180 1.66732 59.0001)"
                fill="#3758F9"
              />
              <circle
                cx="1.66732"
                cy="30.6668"
                r="1.66667"
                transform="rotate(180 1.66732 30.6668)"
                fill="#3758F9"
              />
              <circle
                cx="1.66732"
                cy="1.66683"
                r="1.66667"
                transform="rotate(180 1.66732 1.66683)"
                fill="#3758F9"
              />
            </svg>
          </span>
        </div>
      </section>

      {/* Contact */}
      <section id="contact" className="relative py-20 md:py-[120px]">
        <div className="absolute left-0 top-0 -z-[1] h-full w-full dark:bg-dark"></div>
        <div className="absolute left-0 top-0 -z-[1] h-1/2 w-full bg-[#E9F9FF] dark:bg-dark-700 lg:h-[45%] xl:h-1/2"></div>
        <div className="container px-4">
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="w-full px-4 lg:w-7/12 xl:w-8/12">
              <div className="ud-contact-content-wrapper">
                <div className="ud-contact-title mb-12 lg:mb-[150px]">
                  <span className="mb-2 block text-base font-medium text-dark dark:text-white text-start ms-[2rem]">
                    COMUNICATE
                  </span>
                  <h2 className="max-w-[260px] text-3xl font-semibold  text-dark dark:text-white">
                    ¡Contáctanos para soluciones profesionales!
                  </h2>
                </div>
                <div className="mb-12 flex flex-wrap justify-between lg:mb-0">

                  <div className="mb-8 flex w-[330px] max-w-full">
                    <div className="mr-6 text-[32px] text-primary">
                      <svg
                        width="34"
                        height="25"
                        viewBox="0 0 34 25"
                        className="fill-current"
                      >
                        <path d="M30.5156 0.960938H3.17188C1.42188 0.960938 0 2.38281 0 4.13281V20.9219C0 22.6719 1.42188 24.0938 3.17188 24.0938H30.5156C32.2656 24.0938 33.6875 22.6719 33.6875 20.9219V4.13281C33.6875 2.38281 32.2656 0.960938 30.5156 0.960938ZM30.5156 2.875C30.7891 2.875 31.0078 2.92969 31.2266 3.09375L17.6094 11.3516C17.1172 11.625 16.5703 11.625 16.0781 11.3516L2.46094 3.09375C2.67969 2.98438 2.89844 2.875 3.17188 2.875H30.5156ZM30.5156 22.125H3.17188C2.51562 22.125 1.91406 21.5781 1.91406 20.8672V5.00781L15.0391 12.9922C15.5859 13.3203 16.1875 13.4844 16.7891 13.4844C17.3906 13.4844 17.9922 13.3203 18.5391 12.9922L31.6641 5.00781V20.8672C31.7734 21.5781 31.1719 22.125 30.5156 22.125Z" />
                      </svg>
                    </div>
                    <div className="flex flex-col">
                      <h5 className="mb-[18px] text-lg font-semibold text-dark dark:text-white text-start">
                        Podemos ayudarte?
                      </h5>
                      <a
                        href="mailto:carlos.emprendizar@gmail.com"
                        target="_blank"
                        className="text-base text-body-color dark:text-dark-6"
                      >
                        administracion@auditrix.site
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full px-4 lg:w-5/12 xl:w-4/12">
              <div
                className="wow fadeInUp rounded-lg bg-white px-8 py-10 shadow-testimonial dark:bg-dark-2 dark:shadow-none sm:px-10 sm:py-12 md:p-[40px] lg:p-10 lg:px-10 lg:py-12 2xl:p-[40px]"
                data-wow-delay=".2s
              "
              >
                <h3 className="mb-8 text-2xl font-semibold text-dark dark:text-white md:text-[28px] md:leading-[1.42]">
                  Envianos un <br /> mensaje
                </h3>
                <HomeForm onSubmit={(value) => handleSubmit(value)} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <a
        href="#"
        className="back-to-top fixed bottom-8 left-auto right-8 z-[999] hidden h-10 w-10 items-center justify-center rounded-md bg-primary text-white shadow-md transition duration-300 ease-in-out hover:bg-dark"
      >
        <span className="mt-[6px] h-3 w-3 rotate-45 border-l border-t border-white"></span>
      </a>
      <div id="footer">
      <Footer />

      </div>
    </div>
  );
};

export default Home;
