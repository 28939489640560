import { BaseResponse } from "shared/wrappers/BaseResponse";
import { GearTypeContract } from "./Contracts";
import ApiService from "../../apiService/api";
import { setAllGears } from "../../store/slices/gear/gearAllSlice";
import { store } from "../../store/store";

const urlBase = "engranajetipo";
const endpoints = {
    GetGearWithRelation: 'GetGearWithRelation'
}

const GetGearWithRelation = async () : Promise<BaseResponse<GearTypeContract[]> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetGearWithRelation;
    var response = await ApiService.get<GearTypeContract[]>(finalUrl)

    //Guardo estado
    store.dispatch(setAllGears(response?.data))
    return response;
}

const GetUrlComplete = {
    GetGearWithRelation: `${urlBase}/${endpoints.GetGearWithRelation}`.toLowerCase()
}

export const GearTypeService = {
    GetGearWithRelation,
    GetUrlComplete
}