import React, { useEffect, useState } from "react";
import CustomAccordion from "../../components/accordeon/CustomAccordion";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { gearlAll } from "../../store/slices/gear/gearAllSlice";
import { connectionPendings } from "../../store/slices/loadingConnectionSlice";
import { QuestionService } from "../../services/question/QuestionService";
import { GearTypeService } from "../../services/engranajeTipo/GearTypeService";
import { setCurrentGear } from "../../store/slices/gear/currentGearSlice";
import {
  changeComponentQuestion,
  getCurrentComponentQuestion,
} from "../../store/slices/question/changeComponentQuestionSlice";
import QuestionComponentsService from "../../../features/question/QuestionComponentsService";
import GearSkeletonLoading from "./GearSkeletonLoading";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { modalService } from "../../../shared/components/modal/ModalService";
import { GetUserInfoSelector } from "../../../shared/store/slices/user/UserInfoSlice";
import { Permission, PermissionService } from "../../../shared/permissions";
import { Common } from "../../../shared/common";

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const [hasItemSelected, setHasItemSelected] = useState<number>(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Estado para controlar el sidebar
  const [gearName, setGearName] = useState<GearNameProps[]>([]);
  const allGearsEndpoint = useAppSelector(gearlAll);
  const currentComponent = useAppSelector(getCurrentComponentQuestion);
  const isLoading = useAppSelector(connectionPendings);
  const userInfo = useAppSelector(GetUserInfoSelector);
  const { logout, user } = useAuth0();

  const getGearsService = async () => {
    var result = await GearTypeService.GetGearWithRelation();
    if(result && !result.hasError){
      var names = result.data.map(x => {
        let name: string;

        if(x.name === Common.GearNames.Liderazgo && userInfo.user?.roleName === PermissionService.PermissionsObject.product_owner.roleName){
          name = "Liderazgo - Autopercepción";
        }
        else if(x.name === Common.GearNames.Bienestar){
          name = "Bienestar personal";
        }
        else if(x.name === Common.GearNames.Liderazgo && userInfo.user?.roleName !== PermissionService.PermissionsObject.product_owner.roleName){
          name = "Liderazgo - Del superior inmediato";
        }
        else{
          name = x.name;
        }

        let gearName: GearNameProps = {
          id: x.id,
          name
        }

        return gearName;

      })

      setGearName(names);
    }
  };

  const selectCurrentQuestion = async (id: number, idGear?: number) => {
    if (!currentComponent?.includes(QuestionComponentsService.questions))
      dispatch(changeComponentQuestion(QuestionComponentsService.questions));

    await QuestionService.GetQuestionByTeethId(id, 1);

    if (idGear) {
      dispatch(setCurrentGear(allGearsEndpoint.find((x) => x.id === idGear)));
    }
  };

  useEffect(() => {
    getGearsService();
    dispatch(changeComponentQuestion(QuestionComponentsService.home));
  }, []);

  return (
    <section className="fadeInUp z-10 bg-[#090E34] pt-3 h-screen fixed top-0 right-0">
      {/* <!-- Breadcrumb --> */}
      <div className="bg-page fixed top-0 inset-x-0 z-50 px-4 sm:px-6 md:px-8 lg:hidden">
        <div className="flex justify-end items-center py-2">

          {/* Botón de hamburguesa */}
          <button
            type="button"
            className="py-2 px-3 rounded-lg bg-primary text-white hover:text-black"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)} // Cambiar visibilidad del sidebar
            aria-label="Sidebar"
          >
            <svg
              className="flex-shrink-0 size-6"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M17 8L21 12L17 16M3 12H13M3 6H13M3 18H13" />
            </svg>
            <span className="sr-only">Sidebar</span>
          </button>
          {/* <!-- End Sidebar --> */}
        </div>
      </div>
      {/* <!-- End Breadcrumb --> */}

      {/* Sidebar */}
      <div
        id="application-sidebar"
        className={`mt-14 w-screen lg:mt-auto hs-overlay [--auto-close:lg] hs-overlay-open:translate-x-0 transition-all duration-300 transform md:w-[260px] fixed inset-y-0 start-0 z-[30] bg-page lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
      >
        {/* Contenido del sidebar */}
        <div className="px-8 pt-4 flex justify-between mt-12 md:mt-0">
          <Link
            className="flex-none rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80 text-white"
            to="/"
            aria-label="Preline"
          >
            AUDITRIX
          </Link>
          {PermissionService.HasPermissionRequired(
            userInfo.permissions,
            PermissionService.PackPermission.productOwner
          ) && (
            <div title="Invitar miembros">
              <GroupAddIcon
                onClick={() => modalService.InviteCompanyLink()}
                className="text-[#00ff4c] cursor-pointer"
              />
            </div>
          )}
        </div>

        <p className="text-md font-bold px-8 mt-4 text-white">{userInfo.company?.name}</p>
        {userInfo.user?.roleName.includes("Dueño") && (
        <p className="text-sm font-bold px-8 text-[#c4c2c2]">
          {userInfo.user?.roleName === "Dueño" ? "Cuenta principal" : ""}
        </p>
      )}

        <nav
          className="hs-accordion-group p-6 w-full flex flex-col flex-wrap overflow-y-auto max-h-[70%]"
          data-hs-accordion-always-open
        >
          <ul className="space-y-1.5">
            {isLoading.includes(
              GearTypeService.GetUrlComplete.GetGearWithRelation
            ) ? (
              <GearSkeletonLoading />
            ) : (
              allGearsEndpoint.map((gear) => (
                <CustomAccordion
                  key={gear.id}
                  title={gearName.find(x => x.id === gear.id)?.name ?? ""}
                  links={gear.dientes}
                  onLinkClick={(value) => {
                    selectCurrentQuestion(value, gear.id);
                    setHasItemSelected(value);
                    setIsSidebarOpen(!isSidebarOpen);
                  }}
                  questionSelected={hasItemSelected}
                />
              ))
            )}

            {isLoading.includes(
              GearTypeService.GetUrlComplete.GetGearWithRelation
            ) ? (
              <ul className="space-y-1.5 animate-pulse">
                <div className="bg-gray-200 rounded w-full h-4 p-3 mt-5"></div>
              </ul>
            ) : (
              <p
                className="p-3 cursor-pointer text-white bg-btn-primary text-center !mt-4 hover:bg-[#5931f1]"
                onClick={() => {
                  dispatch(
                    changeComponentQuestion(QuestionComponentsService.results)
                  );
                }}
              >
                Resultados
              </p>
            )}
          </ul>

          <div className="absolute bottom-0 left-0 w-full">
            <p className="text-sm text-white bg-[#5931f1] mb-4 mx-2 text-center rounded-md">{user?.email}</p>
            <p
            className="text-white text-center p-4 cursor-pointer bg-btn-primary hover:bg-[#5931f1]"
              onClick={() => logout()}
            >
              Cerrar sesión
            </p>
          </div>
        </nav>
      </div>
      {/* <!-- End Sidebar --> */}
    </section>
  );
};

export default Sidebar;

interface GearNameProps{
  id: number;
  name: string;
}