import LoginButton from "../../loginButton/LoginButton";
import { modalService } from "../ModalService";
import ModalTemplate from "../ModalTemplate";

const InviteCompanyUserCreatedModal = () => {

    
    const actionBotton = undefined;
    const cancelBotton = undefined;
    const level = "success";

    const handleOnRetry = (value: boolean) =>{
        console.log("Arar");
        modalService.closeModal("InviteCompanyUserCreatedModal");
    }

  return (
    <ModalTemplate 
    level={level}
    actionBotton={actionBotton} 
    cancelBotton={cancelBotton}
    onClicked={(value) => handleOnRetry(value)}
    title="Usuario creado" >
        <>
        <p>¡Usuario creado correctamente!</p>
        <p>Ahora puedes iniciar sesión con tus credenciales mediante el siguiente boton</p>
        <div className="md:relative md:right-[2rem] text-center">
            <LoginButton className="p-2 rounded-lg bg-[#2bf02b] text-white mt-2"/>
            <button onClick={() => handleOnRetry(false)} className=" ml-4 p-2 rounded-lg bg-page text-white mt-2">Continuar</button>
        </div>
        </>
    </ModalTemplate>
  );
};

export default InviteCompanyUserCreatedModal;