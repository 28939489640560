import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

interface FaqAccordionProps {
  question: string;
  answer: string;
}

const FaqAccordion: React.FC<FaqAccordionProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 w-full md:w-1/2 lg:w-auto">
      <button
        className="text-left w-full px-4 py-3 text-lg font-medium flex justify-between items-center focus:outline-none"
        onClick={toggleAccordion}
      >
        <span className="flex-grow">{question}</span>
        <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} className="text-gray-600" />
      </button>
      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${isOpen ? 'max-h-96' : 'max-h-0'}`}
      >
        <p className="px-4 py-3 text-start">{answer}</p>
      </div>
    </div>
  );
};

export default FaqAccordion;
