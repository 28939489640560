import ApiService from "../../apiService/api";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { GetResultsByCompanyIdResponseDto } from "./Contracts";

const urlBase = "empresa";
const endpoints = {
    GetResultsByCompanyId: 'GetResultsByCompanyId'
}

const GetResultsByCompanyId = async () : Promise<BaseResponse<GetResultsByCompanyIdResponseDto[]> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetResultsByCompanyId;
    var response = await ApiService.get<GetResultsByCompanyIdResponseDto[]>(finalUrl)

    return response;
}

const GetUrlComplete = {
    GetResultsByCompanyId: `${urlBase}/${endpoints.GetResultsByCompanyId}`.toLowerCase()
}

export const EmpresaService = {
    GetResultsByCompanyId,
    GetUrlComplete
}