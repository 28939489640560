import { useEffect, useState } from "react";
import { useAppSelector } from "../../../shared/store/hooks";
import { connectionPendings } from "../../../shared/store/slices/loadingConnectionSlice";
import { GearTypeService } from "../../../shared/services/engranajeTipo/GearTypeService";
import { GetUserInfoSelector } from "../../../shared/store/slices/user/UserInfoSlice";

const QuestionHome = () => {
  const [gearLoading, setGearLoading] = useState<Boolean>(true);
  const loadingConnection = useAppSelector(connectionPendings);
  const userInfo = useAppSelector(GetUserInfoSelector);

  useEffect(() => {
    setGearLoading(
      loadingConnection.includes(
        GearTypeService.GetUrlComplete.GetGearWithRelation
      )
    );
  }, [loadingConnection]);

  if (gearLoading)
    return (
      <div className="animate-pulse">
        <div className="w-2/3 h-6 bg-gray-200 rounded mt-4"></div>
        <div className="w-full h-4 bg-gray-200 rounded mt-8"></div>
        <div className="w-1/2 h-4 bg-gray-200 rounded mt-3"></div>
        <div className="w-1/3 h-4 bg-gray-200 rounded mt-3"></div>
      </div>
    );
  else
    return (
      <div>
        <h1 className="text-3xl font-bold mt-4">
          Bienvenido al módulo de auditoría sistémica
        </h1>
        <p className="my-8 font-semibold text-xl">
          AUDITRIX es un modelo de testeo interdisciplinario diseñado para
          contribuir a la mejora continua de todo el sistema {userInfo.company?.name.toUpperCase()} y
          profundizar el mismo con el desarrollo de su IA generativa
          personalizada.
        </p>
        <p className="font-semibold mb-8 text-xl">
          Para ello es necesario contestar las preguntas y observaciones de cada
          subsistema y compartir acceso a todas las personas de la organización.
          Quienes deberán crear su cuenta personal e Iniciar sesión, para poder
          responder con absoluta reserva y confidencialidad sus datos y
          respuestas.
        </p>

        <p className="font-semibold mb-8 text-xl">
          Cada miembro de la organización podrá ver en su cuenta personal, sólo
          los resultados de su auditoría.
        </p>
        <p className="font-semibold text-xl">
          Mientras que sólo en esta <span className="font-extrabold">cuenta principal</span> se podrán ver los
          resultados promedio globales del sistema. A fin de diseñar un plan de
          mejora continua hacia la integridad sistémica en {userInfo.company?.name.toUpperCase()}.
        </p>
      </div>
    );
};

export default QuestionHome;
