import { UsuarioService } from "../../shared/services/usuario/UsuarioService";
import { setToken } from "../store/slices/tokenSlice";
import { store } from "../store/store";

const saveAuthentication = async (token: string) =>{
    try {
        store.dispatch(setToken(token));

        await UsuarioService.GetUserInfo();

      } catch (e) {
        console.log(e);
      }
}

export const AuthenticationService = {
    saveAuthentication
}