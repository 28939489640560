import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface changeComponentQuestionSliceProp{
  name: string | undefined;
}

const initialState: changeComponentQuestionSliceProp = {
    name: undefined
};
export const changeComponentQuestionSlice = createSlice({
  name: "changeComponentQuestion",
  initialState,
  reducers: {
    changeComponentQuestion: (state, action: PayloadAction<string | undefined>) => {
        if(action.payload)
        {
          state.name = action.payload;
        }
    }
  },
});
export const { changeComponentQuestion } = changeComponentQuestionSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const getCurrentComponentQuestion = (state: RootState) => state.changeComponentQuestionSlice.name;
export default changeComponentQuestionSlice.reducer;