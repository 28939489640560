import { configureStore } from "@reduxjs/toolkit";
import { loadingConnectionSlice } from "./slices/loadingConnectionSlice";
import { currentQuestionSlice } from "./slices/question/currentQuestionSlice";
import { gearAllSlice } from "./slices/gear/gearAllSlice";
import { currentApplySlice } from "./slices/apply/currentApplySlice";
import { tokenSlice } from "./slices/tokenSlice";
import { routerConfigSlice } from "./slices/routerConfigSlice";
import { currentGearSlice } from "./slices/gear/currentGearSlice";
import { changeComponentQuestionSlice } from "./slices/question/changeComponentQuestionSlice";
import { showToastSlice } from "./slices/showToastSlice";
import { modalSlice } from "./slices/modalSlice";
import { UserInfoSlice } from "./slices/user/UserInfoSlice";


//import { handlerNotificationSlice } from "./Slices/HandlerNotificationSlice";
// ...
export const store = configureStore({
  reducer: {
    loadingConnection: loadingConnectionSlice.reducer,
    currentQuestion: currentQuestionSlice.reducer,
    allGear: gearAllSlice.reducer,
    currentApply: currentApplySlice.reducer,
    token: tokenSlice.reducer,
    routerConfig: routerConfigSlice.reducer,
    currentGearSlice: currentGearSlice.reducer,
    changeComponentQuestionSlice: changeComponentQuestionSlice.reducer,
    showToastSlice: showToastSlice.reducer,
    modalSlice: modalSlice.reducer,
    UserInfoSlice: UserInfoSlice.reducer,
  },
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;