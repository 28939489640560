import { useEffect, useState } from "react";
import { Common } from "../../../common";
import { modalService } from "../ModalService";
import ModalTemplate from "../ModalTemplate";
import {  useAppSelector } from "../../../store/hooks";

import { toastService } from "../../toast/toastService";
import { store } from "../../../../shared/store/store";
import { PermissionService } from "../../../../shared/permissions";
import { GetUserInfoSelector } from "../../../../shared/store/slices/user/UserInfoSlice";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const InviteCompanyLinkModal = () => {
    const link = "/inviteCompany";
    const actionBotton = undefined;
    const cancelBotton = "Cerrar";
    const level = "warning";
    const userInfo = useAppSelector(GetUserInfoSelector);

    const handleOnRetry = (value: boolean) =>{
        console.log("Arar");
        modalService.closeModal("InviteCompanyLinkModal");
    }

    const handleEncryptCompanyId = () : string =>{
        var companyId = store.getState().UserInfoSlice.company?.id;
        var companyIdEncrypt : string = Common.EncryptNumber(companyId?.toString()??"");

        return companyIdEncrypt;
    }

    const handleGetUrlWithoutRole = () => {

        const companyIdEncrypt = handleEncryptCompanyId();
        const urlBase = window.location.origin;

        const finalUrl = `${urlBase}${link}?id=${companyIdEncrypt}`

        return finalUrl;

    }

    const handleGetUrlWithRole = () => {
        var roleName = PermissionService.PermissionsObject.product_directive.name

        var roleEncrypt : string = Common.EncryptNumber(roleName);
        const companyIdEncrypt = handleEncryptCompanyId();

        const urlBase = window.location.origin;

        const finalUrl = `${urlBase}${link}?id=${companyIdEncrypt}&role=${roleEncrypt}`

        return finalUrl;

    }

    const handleCopyLink = async (withRole: boolean) =>{
        var linkInvitacion = withRole ? handleGetUrlWithRole() : handleGetUrlWithoutRole();

        if(linkInvitacion){
            await navigator.clipboard.writeText(linkInvitacion);
            toastService.info("¡Link de invitación copiado!");
        }else{
            toastService.error("Error al obtener el link de invitación");
        }
    }

  return (
    <ModalTemplate 
    level={level}
    actionBotton={actionBotton} 
    cancelBotton={cancelBotton}
    onClicked={(value) => handleOnRetry(value)}
    title="COMPARTIR ACCESO" >
        <>
        <p className="text-black text-xl mt-5">
        Link de invitación válido sólo para integrantes de {userInfo.company?.name.toUpperCase()}
        </p>
        <div className="md:relative sm:right-[2rem] text-center">
            <button onClick={() => handleCopyLink(true)} className="ml-4 p-2 rounded-lg bg-page text-white mt-2 box-shadow-inset">auditrix.site/inviteLink<span className="ms-4 text-white"><ContentCopyIcon/></span></button>

        </div>
        </>
    </ModalTemplate>
  );
};

export default InviteCompanyLinkModal;