import { Common } from "../../../shared/common";
import GearSvg, { teeth } from "./GearSvg";

const GearThreeWithAnimationSvg = ({
  teethsBienestar,
  teethsLiderazgo,
  teethsOperaciones,
  isBreaking
}: GearThreeWithAnimationSvgProps) => {

  const animation = isBreaking ? 'animate-spin-wobble' : 'animate-spin-slow'

  return (
    <div className="flex justify-center mt-20">
      <div className="relative left-[7rem] bottom-[2.6rem] sm:left-[7rem] sm:bottom-[2rem] z-10 w-full h-full">
        <GearSvg
          className={`${animation} sm:w-[16rem] sm:h-[16rem] w-[12rem] h-[12rem] rotate-[21deg]`}
          teethAmount={teethsLiderazgo}
        />
        <div className="absolute top-0 left-0 sm:w-[16rem] sm:h-[16rem] w-[12rem] h-[12rem] flex justify-center items-center">
          <span className="text-white">
            {Common.GearNames.Liderazgo}
          </span>
        </div>
      </div>

      <div className="relative top-[5rem] left-[1rem] sm:top-[8rem] sm:left-0 z-20 h-full w-full">
        <GearSvg
          className={`${animation} sm:w-[10rem] sm:h-[10rem] w-[8rem] h-[8rem]`}
          teethAmount={teethsBienestar}
        />
        <div className="absolute top-0 left-0 sm:w-[10rem] sm:h-[10rem] w-[8rem] h-[8rem] flex justify-center items-center">
          <span className="text-white">
            {Common.GearNames.Bienestar}
          </span>
        </div>
      </div>

      <div className="relative right-[5rem] bottom-[5rem] sm:right-[8rem] z-20 h-full w-full">
        <GearSvg
          className={`${animation} sm:w-[20rem] sm:h-[20rem] w-[16rem] h-[16rem]`}
          teethAmount={teethsOperaciones}
        />
        <div className="absolute top-0 left-0 sm:w-[20rem] sm:h-[20rem] w-[16rem] h-[16rem] flex justify-center items-center">
          <span className="text-white">
            {Common.GearNames.Operaciones}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GearThreeWithAnimationSvg;

interface GearThreeWithAnimationSvgProps {
  teethsLiderazgo: teeth[];
  teethsBienestar: teeth[];
  teethsOperaciones: teeth[];
  isBreaking: boolean
}
