import ApiService from "../../apiService/api";
import { setCurrentApply } from "../../store/slices/apply/currentApplySlice";
import { store } from "../../store/store";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { GetEmpresaInfoContractResponse } from "./Contract";

const urlBase = "cantidadempleado";
const endpoints = {
    GetEmpresaInfo: 'GetAll'
}

const GetEmpresaInfo = async () : Promise<BaseResponse<GetEmpresaInfoContractResponse[]> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetEmpresaInfo;
    var response = await ApiService.get<GetEmpresaInfoContractResponse[]>(finalUrl)

    //Guardo estado
    console.log(response?.data, "que tinee");
    store.dispatch(setCurrentApply(response?.data))
    return response;
}



const GetUrlComplete = {
    GetEmpresaInfo: `${urlBase}/${endpoints.GetEmpresaInfo}`.toLowerCase()
}

export const CantidadEmpleadoService = {
    GetEmpresaInfo,
    GetUrlComplete
}