import { useEffect, useState } from "react";
import { useAppSelector } from "../../../shared/store/hooks";
import { ModalSelector } from "../../../shared/store/slices/modalSlice";
import { modalRegistered } from "./ModalService";

const ModalContainer = () => {
  const [showModal, setShowModal] = useState<modalPropsToShow[]>();
  const modal = useAppSelector(ModalSelector);

  const handleShowModal = () => {
    var modalRegisteredKey = Object.keys(modalRegistered);

    for (let index = 0; index < modal.length; index++) {
      //Elemento iterado
      const modalElement = modal[index];

      //Quitamos el modal si asi lo quiere.
      if (!modalElement.show) {

        if(showModal && showModal.length > 0){

          if(showModal.length > 1){
            var modalElementRemoved = showModal?.filter(
              (x) => x.name !== modalElement.name
            );
            setShowModal(modalElementRemoved);
          }else{
            setShowModal([])
          }

        }
       
      }else{

        //Key de los modales registrados
        const modalKey = modalRegisteredKey.find((x) =>
          x.includes(modalElement.name)
        );

        //Modal a mostrar
        //(siempre va a venir un modalKey, si no viene estas linas no se ejecutan)
        const elementToShow = modalKey ? modalRegistered[modalKey] : undefined;

        //Configuracion del modal a mostrar.
        var props: modalPropsToShow = {
          element: elementToShow,
          name: modalElement.name,
        };

        //Si la variable no esta cargada, inicializamos con el modal a mostrar
        if (!showModal || showModal.length === 0) {
          setShowModal([props]);
        } else {
          //De lo contrario agregamos el modal para mostrar
          setShowModal([...showModal, props]);
        }

      }
    }
  };

  useEffect(() => {
    handleShowModal();
  }, [modal]);
  
  return (
    <>
      {showModal?.map(ModalAMostrar => {
        const ModalComponent = ModalAMostrar.element;

        return(
            <ModalComponent key={ModalAMostrar.name} />
        )
      })}
    </>
  );
};

export default ModalContainer;

interface modalPropsToShow {
  name: string;
  element: React.ElementType;
}
