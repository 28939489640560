import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";

interface LoginButtonProps {
  loginText?: string;
  logoutText?: string;
  className?: string;
  loadingClass?: string;
}

const LoginButton = ({
  loginText = "Iniciar sesión",
  logoutText = "Cerrar sesión",
  className = "btn-default",
  loadingClass = "loading-default",
}: LoginButtonProps) => {
  const { loginWithRedirect, isAuthenticated, logout, isLoading } = useAuth0();

  const [userAuthenticated, setUserAuthenticated] = useState<ReactElement>(
    <button onClick={() => loginWithRedirect()} className={className}>
      {loginText}
    </button>
  );

  useEffect(() => {
    if (isLoading) {
      setUserAuthenticated(<CircularProgress className={loadingClass} />);
    } else if (isAuthenticated) {
      setUserAuthenticated(
        <button onClick={() => logout()} className={className}>
          {logoutText}
        </button>
      );
    } else {
      setUserAuthenticated(
        <button onClick={() => loginWithRedirect()} className={className}>
          {loginText}
        </button>
      );
    }
  }, [isAuthenticated, isLoading, loginText, logoutText, className, loadingClass]);

  return userAuthenticated;
};

export default LoginButton;
