import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
// Define a type for the slice state
interface LoadingConnection {
  isPending: string[];
}
// Define the initial state using that type
const initialState: LoadingConnection = {
  isPending: [],
};
export const loadingConnectionSlice = createSlice({
  name: "loadingConnection",
  initialState,
  reducers: {
    addPending: (state, action: PayloadAction<string>) => {
      if (state.isPending.find(x => x === action.payload.toLowerCase()) === undefined)
        state.isPending.push(action.payload.toLowerCase());
    },
    deletePending: (state, action: PayloadAction<string>) => {
      state.isPending = state.isPending.filter(
        (x) => x !== action.payload.toLowerCase()
      );
    },
  },
});
export const { addPending, deletePending } = loadingConnectionSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const connectionPendings = (state: RootState) => state.loadingConnection.isPending;
export default loadingConnectionSlice.reducer;