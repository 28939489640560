import { Auth0Provider } from "@auth0/auth0-react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./shared/store/store";
import { ToastContainer } from 'react-toastify';
import ModalContainer from "./shared/components/modal/ModalContainer";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Auth0Provider
    domain="integrityaudit.us.auth0.com"
    clientId="t6T9nzFM6ksnx28NQDuETyGhrs71Ht2B"
    authorizationParams={{
      redirect_uri: `${window.location.origin}/audit`,
      audience: "integrity_backend_auth",
      scope: "openid profile email",
    }}
  >
    <Provider store={store}>
      <ModalContainer/>
      <ToastContainer/>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>
  </Auth0Provider>
);
