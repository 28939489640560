import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { RouteConfigProps } from "../../routeConfig/routeConfigInterface";
import { ToastOptions } from "react-toastify";

// Define the initial state using that type
export interface showToastProp {
  content: string | undefined;
  options?: ToastOptions<unknown> | undefined;
  type: "error" | "info" | "success" | "warning"
}

const initialState: showToastProp = {
  content: undefined,
  type: "error"
};
export const showToastSlice = createSlice({
  name: "showToast",
  initialState,
  reducers: {
    SetShowToast: (state, action: PayloadAction<showToastProp>) => {
      if (action.payload !== null && action.payload) {
        state.content = action.payload.content;
        state.options = action.payload.options;
        state.type = action.payload.type;
      }
    },
  },
});
export const { SetShowToast } = showToastSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const ShowToastSelector = (state: RootState) => state.showToastSlice;
export default showToastSlice.reducer;
