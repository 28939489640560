const PermissionsObject : PermissionObject = {
    plan_one: { name: "plan:one", description: "Acceso a plan 1", roleName: "Acceso a plan 1" },
    plan_two: { name: "plan:two", description: "Acceso a plan 2", roleName: "Acceso a plan 2" },
    plan_three: { name: "plan:three", description: "Acceso a plan 3", roleName: "Acceso a plan 3" },
    plan_all: { name: "plan:*", description: "Acceso a todos los planes", roleName: "Acceso a todos los planes" },
    product_owner: { name: "product:owner", description: "Acceso a toda la plataforma de Producto", roleName: "Dueño" },
    product_admin: { name: "product:admin", description: "Acceso limitado a toda la plataforma de Producto", roleName: "Admin" },
    product_member: { name: "product:member", description: "Acceso solo a responder las preguntas de los engranajes (excluyendo Organizacion)", roleName: "Miembro" },
    product_directive: { name: "product:directive", description: "Acceso a todos los engranajes, sin reportes general", roleName: "Directivo" },
    staff_owner: { name: "staff:owner", description: "Acceso a toda la aplicacion", roleName: "DueñoSW" },
    staff_admin: { name: "staff:admin", description: "Acceso limitado a toda la aplicacion", roleName: "AdminSW" }
};

const PackPermission : PackPermission = {
    productOwner: [PermissionsObject.product_owner, PermissionsObject.staff_owner, PermissionsObject.staff_admin],
    platformOwner: [PermissionsObject.staff_owner,PermissionsObject.staff_admin],
}

const HasPermissionRequired = (permissions: Permission[] | null | undefined, permissionsRequired: Permission[]): boolean => {
    let hasRole = false;
  
    if (!permissions || permissions.length === 0) {
      return hasRole;
    }
  
    const required = permissionsRequired.map(x => x.name);

    // Si no tiene permisos requeridos entonces doy permiso
    if (!required) {
      return true;
    }
      
    for (let i = 0; i < permissions.length; i++) {
      if (required.includes(permissions[i].name)) {
        hasRole = true;
        break;
      }
    }
  
    return hasRole;
  };

export const PermissionService = {
    PermissionsObject,
    PackPermission,
    HasPermissionRequired
}


export interface PackPermission {
    [key: string] : Permission[]
}


export interface Permission {
    name: string;
    description: string;
    roleName: string;
}

export interface PermissionObject{
    [key:string] : Permission
}