import React, { useState } from "react";

interface SimpleCardProps {
  title: string;
  defaultDescription: string;
  hoverDescription: string;
  imageSrc: string; // Nueva propiedad para el src de la imagen
}

const SimpleCard: React.FC<SimpleCardProps> = ({
  title,
  defaultDescription,
  hoverDescription,
  imageSrc, // Nuevo prop para el src de la imagen
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="w-full px-4 md:w-1/2 lg:w-1/4 relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <div className="absolute inset-0">
          <div className="w-full h-full overflow-hidden rounded-xl bg-white shadow-pricing dark:bg-dark-2 flex flex-col items-center justify-center transform rotate-y-180">
            <p className="text-lg font-medium text-dark dark:text-white max-h-full overflow-hidden text-center px-4">
              {hoverDescription}
            </p>
          </div>
        </div>
      ) : (
        <div className="relative z-10 mb-10 overflow-hidden rounded-xl bg-white  shadow-pricing dark:bg-dark-2  flex flex-col items-center">
          <img
            src={imageSrc}
            alt=""
            className="w-full h-64 object-cover" // Estilos para la imagen
          />
          <h2 className="mb-2 mt-4 text-4xl font-semibold text-dark dark:text-white xl:text-[42px] xl:leading-[1.21]">
            <span className="-ml-1 -tracking-[2px]">{title}</span>
          </h2>
          <div className="mb-4">
            <h5 className="text-lg font-medium text-dark dark:text-white">
              {defaultDescription}
            </h5>
          </div>
        </div>
      )}
    </div>
  );
};

export default SimpleCard;
