import { useAppSelector } from "../../../../shared/store/hooks";
import QuestionResultGlobal from "./QuestionResultGlobal";
import QuestionResultIndividual from "./QuestionResultIndividual";
import { GetUserInfoSelector } from "../../../../shared/store/slices/user/UserInfoSlice";
import { PermissionService } from "../../../../shared/permissions";
import { useEffect, useState } from "react";

const QuestionResults = () => {
  const [hasPermissionForGlobalResults, setHasPermissionForGlobalResults] =
    useState<boolean>(false);
  const userInfo = useAppSelector(GetUserInfoSelector);

  const handleCheckPermission = () =>{
    var hasPermission = PermissionService.HasPermissionRequired(
      userInfo.permissions,
      PermissionService.PackPermission.productOwner
    );

    setHasPermissionForGlobalResults(hasPermission);
  }

  useEffect(() => {
    handleCheckPermission();
  }, [userInfo]);
  return (
    <div>
      <h1 className="py-10 uppercase text-2xl font-bold">
        Accedé a los resultados de tu auditoría
      </h1>

      <QuestionResultIndividual />

      {hasPermissionForGlobalResults && <QuestionResultGlobal />}
    </div>
  );
};

export default QuestionResults;
