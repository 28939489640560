import { ToastOptions } from "react-toastify"
import { SetShowToast, showToastProp } from "../../../shared/store/slices/showToastSlice"
import { store } from "../../../shared/store/store"

const error = (content: string, autoClose?: number) =>{

    //Configuracion de estilo y posicionado.
    const options : ToastOptions<unknown> | undefined = {
        position: "top-right",
        autoClose: autoClose??5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    }
    
    const type = "error";

    var props : showToastProp ={
        content: content,
        type: type,
        options: options
    }

    //Lanzo el toast.
    store.dispatch(SetShowToast(props));
}

const info = (content: string, autoClose?: number) =>{

    //Configuracion de estilo y posicionado.
    const options : ToastOptions<unknown> | undefined = {
        position: "top-right",
        autoClose: autoClose??5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    }
    
    const type = "info";

    var props : showToastProp ={
        content: content,
        type: type,
        options: options
    }

    //Lanzo el toast.
    store.dispatch(SetShowToast(props));
}

const success = (content: string, autoClose?: number) =>{

    //Configuracion de estilo y posicionado.
    const options : ToastOptions<unknown> | undefined = {
        position: "top-right",
        autoClose: autoClose??5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    }
    
    const type = "success";

    var props : showToastProp ={
        content: content,
        type: type,
        options: options
    }

    //Lanzo el toast.
    store.dispatch(SetShowToast(props));
}

const warning = (content: string, autoClose?: number) =>{

    //Configuracion de estilo y posicionado.
    const options : ToastOptions<unknown> | undefined = {
        position: "top-right",
        autoClose: autoClose??5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    }
    
    const type = "warning";

    var props : showToastProp ={
        content: content,
        type: type,
        options: options
    }

    //Lanzo el toast.
    store.dispatch(SetShowToast(props));
}

export const toastService = {
    error,
    info,
    success,
    warning
}

export const toastTypes = {
    error: "error",
    info: "info",
    success: "success",
    warning: "warning"
}