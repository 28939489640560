import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import SimpleLoading from '../../../shared/IconSvg/Spinner/SimpleLoading';

const Navbar = (props: propsNavBar = { withColor: false }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, isLoading } = useAuth0();
  const path = useLocation();
  const isApply = path.pathname.includes("apply");

  const menuRef = useRef<HTMLDivElement>(null);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleHomeClick = () => {
    if (window.location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [path]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="ud-header fixed left-0 top-0 z-40 flex w-full items-center bg-[#3758f9]">
      <div className="container">
        <div className="relative -mx-4 flex items-center justify-between">
          <div className="w-60 max-w-full px-4">
            <Link to="/" onClick={handleHomeClick} className="text-3xl integrity ud-menu-scroll mx-8 flex py-2 font-medium text-dark group-hover:text-primary dark:text-white lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70">
              AUDITRIX
            </Link>
          </div>
          <div className="flex w-full items-center justify-between px-4">
            <div>
              <button
                id="navbarToggler"
                className="absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden"
                onClick={handleMenuToggle}
              >
                {isMenuOpen ? (
                  <span className="relative block w-6 h-6 mt-4">
                    <span className="absolute top-0 left-0 block w-6 h-0.5 bg-white rotate-45 transform origin-center"></span>
                    <span className="absolute top-0 left-0 block w-6 h-0.5 bg-white -rotate-45 transform origin-center"></span>
                  </span>
                ) : (
                  <>
                    <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                    <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                    <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                  </>
                )}
              </button>
              <nav
                id="navbarCollapse"
                ref={menuRef}
                className={`absolute right-4 top-full ${isMenuOpen ? 'block' : 'hidden'} w-full rounded-lg bg-white py-5 shadow-lg dark:bg-dark-2 lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:px-4 lg:py-0 lg:shadow-none dark:lg:bg-transparent xl:px-6`}
              >
                <ul className="block lg:flex 2xl:ml-20">
                  <li className="group relative">
                    <Link to="/" onClick={handleHomeClick}>
                      <p className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark:text-white lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70">
                        Inicio
                      </p>
                    </Link>
                  </li>
                  <li className="group relative">
                    <Link smooth to="/#about">
                      <p className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark:text-white lg:ml-7 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-10">
                        Nosotros
                      </p>
                    </Link>
                  </li>
                  <li className="group relative">
                    {isApply ? (
                      <Link smooth to="/home#beneficios">
                        <p className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark:text-white lg:ml-7 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-10">
                          Beneficios
                        </p>
                      </Link>
                    ) : (
                      <Link smooth to="/#beneficios">
                        <p className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark:text-white lg:ml-7 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-10">
                          Beneficios
                        </p>
                      </Link>
                    )}
                  </li>
                  <li className="group relative">
                    <Link smooth to="/#usuarios">
                      <p className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark:text-white lg:ml-7 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-10">
                        Usuarios
                      </p>
                    </Link>
                  </li>
                  <li className="group relative">
                    <Link smooth to="/#footer">
                      <p className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark:text-white lg:ml-7 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-10">
                        Servicios
                      </p>
                    </Link>
                  </li>
                  <li className="group relative">
                    <Link smooth to="/#faq">
                      <p className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark:text-white lg:ml-7 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-10">
                        Contacto
                      </p>
                    </Link>
                  </li>
                  <li className="group relative">
                    <Link to="/apply" target="_blank">
                    {
                    isLoading ?
                      <SimpleLoading />
                      :
                      !isAuthenticated
                        ?
                        <Link to="/apply" target='_blank'>
                          <p className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark:text-white lg:ml-7 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-10">
                            Aplicar
                          </p>
                        </Link>
                        :
                        <Link
                          target='_blank'
                          to="/audit">
                          <p className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark:text-white lg:ml-7 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-10">
                            Control panel
                          </p>
                        </Link>
                  }
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface propsNavBar {
  withColor: boolean;
}
export default Navbar;
