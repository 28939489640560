
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetEmpresaInfoContractResponse } from '../../../shared/services/cantidadEmpleados/Contract';

  const validationSchema = Yup.object({
    fullname: Yup.string().required('Nombre y Apellido es requerido'),
    email: Yup.string().email('Email inválido').required('Email es requerido'),
    phone: Yup.string().required('Teléfono es requerido'),
    position: Yup.string().required('Cargo es requerido'),
    employeesCant: Yup.string().required("La cantidad de empleados es requerida."),
    organizationType: Yup.string().required("El tipo de organizacion es requerida."),
    industry: Yup.string().required('Rubro es requerido'),
  });

const ApplyForm = ({companyInfo, organizationType, onSubmit} : ApplyFormProps) =>{

  const initialValues: ApplyFormValues = {
    fullname: '',
    email: '',
    phone: '',
    position: '',
    employeesCant: '',
    organizationType: '',
    industry: '',
  };

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
            resetForm();
            setSubmitting(false);
            onSubmit(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="w-full max-w-md px-8 bg-white rounded">
            <div className="mb-[22px]">
              <Field
                type="text"
                name="fullname"
                placeholder="Nombre y Apellido"
                className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
              />
              <ErrorMessage name="fullname" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="mb-[22px]">
              <Field
                type="email"
                name="email"
                placeholder="Email"
                className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
              />
              <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="mb-[22px]">
              <Field
                type="text"
                name="phone"
                placeholder="Teléfono"
                className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
              />
              <ErrorMessage name="phone" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="mb-[22px]">
              <Field
                type="text"
                name="position"
                placeholder="Cargo"
                className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
              />
              <ErrorMessage name="position" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="mb-[22px]">
              <Field
                as="select"
                name="employeesCant"
                className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
              >
                <option value="" disabled style={{ color: '#aaa' }}>
                  Cantidad de empleados
                </option>
                {companyInfo?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.descripcion}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="employeesCant" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="mb-[22px]">
              <Field
                as="select"
                name="organizationType"
                className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
              >
                <option value="" disabled style={{ color: '#aaa' }}>
                  Tipo de organización
                </option>
                {organizationType?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.descripcion}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="organizationType" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="mb-[22px]">
              <Field
                type="text"
                name="industry"
                placeholder="Rubro"
                className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
              />
              <ErrorMessage name="industry" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="mb-9">
              <button
                type="submit"
                className="w-full px-5 py-3 text-base text-white transition duration-300 ease-in-out border rounded-md cursor-pointer border-primary bg-primary hover:bg-blue-dark"
                disabled={isSubmitting}
              >
                Aplicar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    )
}


export default ApplyForm;

export interface ApplyFormValues {
    fullname: string;
    email: string;
    phone: string;
    position: string;
    employeesCant: string;
    organizationType: string;
    industry: string;
  }

interface ApplyFormProps{
    companyInfo?: GetEmpresaInfoContractResponse[];
    organizationType?: GetEmpresaInfoContractResponse[];
    onSubmit: (value: ApplyFormValues) => void;
}
  