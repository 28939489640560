import ApiService from "../../apiService/api";
import { setCurrentQuestion } from "../../store/slices/question/currentQuestionSlice";
import { store } from "../../store/store";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { GetQuestionByTeethIdContract } from "./Contracts";

const urlBase = "pregunta";
const endpoints = {
    GetQuestionByTeethId: 'GetQuestionByTeethId'
}

const GetQuestionByTeethId = async (id: number, page: number) : Promise<BaseResponse<GetQuestionByTeethIdContract> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetQuestionByTeethId;

    var response = await ApiService.get<GetQuestionByTeethIdContract>(finalUrl, {id, page})

    //Guardo estado
    store.dispatch(setCurrentQuestion(response?.data))
    return response;
}

const GetUrlComplete = {
    GetQuestionByTeethId: `${urlBase}/${endpoints.GetQuestionByTeethId}`.toLowerCase()
}

export const QuestionService = {
    GetQuestionByTeethId,
    GetUrlComplete
}