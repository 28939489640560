import React from "react";

const ButtonPrimary = ({children, className, type, disabled}:props) =>{

    return(
        <button disabled={disabled} type={type} className={`bg-btn-primary text-white p-3 text-center rounded-md ${className}`}>{children}</button>
    )
}

export default ButtonPrimary;

interface props{
    children: React.ReactNode
    className?: string;
    type?: "submit" | "reset" | "button" | undefined;
    disabled?: boolean;
}