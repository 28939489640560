import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
// Define a type for the slice state
interface TokenContract {
  accessToken: string | undefined;
}
// Define the initial state using that type
const initialState: TokenContract = {
  accessToken: undefined
};
export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.accessToken = action.payload;
    }
  },
});
export const { setToken } = tokenSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const GetTokenSelector = (state: RootState) => state.token;
export default tokenSlice.reducer;