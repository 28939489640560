import { EmpresaDientesGearResultResponse } from "../../../shared/services/empresa/Contracts";
import { DientesGearResultResponse } from "../../../shared/services/engranaje/Contracts";
import { teeth } from "./GearSvg";
import { Common } from "../../../shared/common";

const TeethAmount = {
  Bienestar: 8,
  Liderazgo: 8,
  Operaciones: 10
}

const HandleSetTeethAmount = (
  dientes:
    | DientesGearResultResponse[]
    | EmpresaDientesGearResultResponse[]
    | undefined
): teeth[] => {
  var teeths: teeth[] = [];

  if (dientes) {
    teeths = dientes.map((diente, index) => {
      var teeth: teeth = {
        diente: index + 1,
        porcentaje: diente.porcentaje,
      };

      return teeth;
    });
  }

  return teeths;
};

const GetTeehFromGear = (gear: string) : teeth[] =>{
  var teethCount : number = 0;
  var teeths : teeth[] = []; 
  switch (gear) {
    case Common.GearNames.Bienestar:
      teethCount = TeethAmount.Bienestar
      break;
    case Common.GearNames.Liderazgo:
      teethCount = TeethAmount.Liderazgo
      break;
    case Common.GearNames.Operaciones:
        teethCount = TeethAmount.Operaciones
        break;
  }

  for (let index = 0; index < teethCount; index++) {
    var teeth: teeth = {
      diente: index + 1,
      porcentaje: 100
    }
    teeths.push(teeth);
  }

  return teeths;
}

export const GearSvgService = {
  HandleSetTeethAmount,
  TeethAmount,
  GetTeehFromGear
};

interface DienteExampleProps {
  teethsLiderazgo: teeth[];
  teethsBienestar: teeth[];
  teethsOperaciones: teeth[];
}

interface GearExamplesProps {
  [key: string]: DienteExampleProps;
}
