import { useState } from 'react';
import { DienteTypeContract } from '../../../shared/services/engranajeTipo/Contracts';

interface CustomAccordionProps {
  title: string;
  links: DienteTypeContract[];
  onLinkClick: (id: number) => void; // Función para pasar el ID seleccionado al padre
  questionSelected: number
}


function CustomAccordion({ title, links, onLinkClick, questionSelected }: CustomAccordionProps) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const handleClick = (link: DienteTypeContract) => {
    onLinkClick(link.id); // Llamar a la función del padre con el ID seleccionado
  };

  return (
    <li className="hs-accordion">
      <button
        type="button"
        className="text-white text-md hs-accordion-toggle w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-blue-600 hs-accordion-active:hover:bg-transparent rounded-lg hover:bg-[#4497f5] dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300 dark:hs-accordion-active:text-white"
        onClick={toggleAccordion}
      >
        <svg
          className="flex-shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#2C3E50"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <rect width="20" height="14" x="2" y="7" rx="2" ry="2" />
          <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
        </svg>

        {title}

        <svg
          className={`hs-accordion-active:block ms-auto size-4 ${
            isAccordionOpen ? '' : 'hidden'
          }`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m18 15-6-6-6 6" />
        </svg>

        <svg
          className={`hs-accordion-active:hidden ms-auto size-4 ${
            isAccordionOpen ? 'hidden' : 'block'
          }`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>
      <div
        className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${
          isAccordionOpen ? 'block' : 'hidden'
        }`}
      >
        <ul className="p-2 bg-[#3688e6] rounded-xl">
          {links.map((link, index) => (
            <li key={index}>
              <p
                className={`cursor-pointer flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-lg hover:bg-[#4497f5] ${link.id === questionSelected ? "bg-[#4497f5]" : ""}`}
                onClick={() => handleClick(link)} // Manejar el clic en el enlace
              >
                {link.name}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
}


export default CustomAccordion;
