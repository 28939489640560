import { store } from "../../../shared/store/store";
import ApiService from "../../apiService/api";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { GetUserInfoResponseDto, UsuarioCreateRequestDto } from "./Contracts";
import { SetUserInfo } from "../../../shared/store/slices/user/UserInfoSlice";

const urlBase = "usuarios";
const endpoints = {
    Crear: 'Crear',
    GetUserInfo: 'GetUserInfo'
}

const Crear = async (data: UsuarioCreateRequestDto) : Promise<BaseResponse<number>| undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.Crear;
    var response = await ApiService.post<number>(finalUrl, null, data)
    return response;
}

const GetUserInfo = async () : Promise<BaseResponse<GetUserInfoResponseDto>| undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetUserInfo;
    var response = await ApiService.get<GetUserInfoResponseDto>(finalUrl)
    store.dispatch(SetUserInfo(response?.data))
    return response;
}

const GetUrlComplete = {
    Crear: `${urlBase}/${endpoints.Crear}`.toLowerCase(),
    GetUserInfo: `${urlBase}/${endpoints.GetUserInfo}`.toLowerCase(),

}

export const UsuarioService = {
    Crear,
    GetUserInfo,
    GetUrlComplete
}