import { toastService } from "../../shared/components/toast/toastService";
import { BaseResponse } from "../../shared/wrappers/BaseResponse";

export const handleNotification = (message: BaseResponse<any> | undefined) =>{

    if(message?.status){
        switch (message.status) {
            case HttpStatusCodes.Success:

                if(message.hasError && message.error){
                    for (let index = 0; index < message?.error?.length; index++) {
                        const element =  message?.error[index];
                        toastService.error(element.description, 5000);                        
                    }
                }else{
                    toastService.info(message.description, 2000);
                }
                
                break; 
            case HttpStatusCodes.Created:
                toastService.success(message.description, 2000);
                break;
            case HttpStatusCodes.Forbidden:
                if(message.error){
                    for (let index = 0; index < message?.error?.length; index++) {
                        const element =  message?.error[index];
                        toastService.error(element.description, 5000);                        
                    }
                }else{
                    toastService.error("No tienes acceso a este recurso", 5000);
                }
                break;
            case HttpStatusCodes.Unauthorized:
                if(message.error){
                    for (let index = 0; index < message?.error?.length; index++) {
                        const element =  message?.error[index];
                        toastService.error(element.description, 5000);                        
                    }
                }else{
                    toastService.error("No tienes los suficientes permisos", 5000);
                }
                break;
            case HttpStatusCodes.BadRequest:
                toastService.error(message.description, 2000);
                break;
            case HttpStatusCodes.InternalError:
                if(message.hasError && message.error){
                    for (let index = 0; index < message?.error?.length; index++) {
                        const element =  message?.error[index];
                        toastService.error(element.description, 5000);                        
                    }
                }
                break;
            case HttpStatusCodes.NotFound:
                toastService.warning(message.description, 3000);
                break;
        }
    }
}

export const HttpStatusCodes = {
    InternalError: 500,
    Success: 200,
    NotFound: 404,
    Created: 201,
    BadRequest: 422,
    Unauthorized: 401,
    Forbidden: 403
}