import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { enc } from "crypto-js";
import CryptoJS from "crypto-js";
import { DientesGearResultResponse } from "./services/engranaje/Contracts";
import { EmpresaDientesGearResultResponse } from "./services/empresa/Contracts";
import { teeth } from "./IconSvg/Gear/GearSvg";

const key = process.env.REACT_APP_KEY_ENCRYPT;

const GetAccessToken = async (): Promise<IdToken | undefined> => {
  const { getIdTokenClaims } = useAuth0();

  var token = await getIdTokenClaims();

  return token;
};

const EncryptNumber = (text: string): string => {
  const encrypted = CryptoJS.AES.encrypt(text, key);

  return encrypted.toString();
};

const DecryptNumber = (text: string): string => {
  console.log(text, "text");
  console.log(key, "key");
  var bytes = CryptoJS.AES.decrypt(text, key);
  const decrypted = bytes.toString(enc.Utf8);

  return decrypted;
};

const GearNames = {
  Liderazgo: "Liderazgo",
  Bienestar: "Bienestar",
  Operaciones: "Operaciones",
};


export const Common = {
  GetAccessToken,
  EncryptNumber,
  DecryptNumber,
  GearNames
};
