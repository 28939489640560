import InviteCompany from "../../features/InviteCompany/InviteCompany";
import Apply from "../../features/apply/Apply";
import Home from "../../features/home/Home";
import Question from "../../features/question/Question";
import { RouteConfigProps } from "./routeConfigInterface";

const routerConfig :RouteConfigProps[] = [
  {path:"/", index:true, component:<Home/>, title:"Auditrix"},
  {path:"/audit", index:false, component:<Question/>, title:"Auditrix - Audit"},
  {path:"/apply", index:false, component:<Apply/>, title:"Auditrix - Apply"},
  {path:"/home", index:false, component:<Home/>, title:"Auditrix"} ,
  {path:"/inviteCompany", index:false, component:<InviteCompany/>, title:"Invitacion"} 

]


export default routerConfig;