import ApiService from "../../apiService/api";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { ResolveQuestionRequest } from "./Contracts";

const urlBase = "dienteresultado";
const endpoints = {
    ResolverPregunta: 'ResolverPregunta'
}

const ResolverPregunta = async (data: ResolveQuestionRequest) : Promise<BaseResponse<number> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.ResolverPregunta;
    var response = await ApiService.post<number>(finalUrl, null, data)

    return response;
}

const GetUrlComplete = {
    ResolverPregunta: `${urlBase}/${endpoints.ResolverPregunta}`.toLowerCase()
}

export const TeethResolveService = {
    ResolverPregunta,
    GetUrlComplete
}