import { ErrorMessage, Field, Form, Formik } from "formik";
import { useAppSelector } from "../../../shared/store/hooks";
import { connectionPendings } from "../../../shared/store/slices/loadingConnectionSlice";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { UsuarioService } from "../../../shared/services/usuario/UsuarioService";
import SimpleLoading from "../../../shared/IconSvg/Spinner/SimpleLoading";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const validationSchema = Yup.object({
  nombre: Yup.string().required("Nombre es requerido"),
  apellido: Yup.string().required("Apellido es requerido"),
  telefono: Yup.string().required("Teléfono es requerido"),
  email: Yup.string().email("Email invalido").required("Email es requerido"),
  password: Yup.string()
    .required("La contraseña es requerida")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&.,]{8,}$/,
      "Debe tener mínimo 8 caracteres, una mayúscula, una minúscula y un número"
    ),
});

const InviteCompanyForm = ({ onSubmit }: InviteCompanyProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const isLoadingConnection = useAppSelector(connectionPendings);

  const initialValues: InviteCompanyFormForm = {
    nombre: "",
    apellido: "",
    telefono: "",
    email: "",
    password: "",
  };

  useEffect(() => {
    setIsLoading(isLoadingConnection.includes(UsuarioService.GetUrlComplete.Crear));
  }, [isLoadingConnection]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        resetForm();
        setSubmitting(false);
        onSubmit(values);
      }}
    >
      {({ isSubmitting }) => (
        <Form className="w-full max-w-md px-8 pt-8 bg-white rounded">
          <div className="mb-[22px]">
            <Field
              type="text"
              name="nombre"
              placeholder="Nombre"
              className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
            />
            <ErrorMessage
              name="nombre"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>

          <div className="mb-[22px]">
            <Field
              type="text"
              name="apellido"
              placeholder="Apellido"
              className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
            />
            <ErrorMessage
              name="apellido"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>

          <div className="mb-[22px]">
            <Field
              type="text"
              name="telefono"
              placeholder="Teléfono"
              className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
            />
            <ErrorMessage
              name="telefono"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>

          <div className="mb-[22px]">
            <Field
              type="text"
              name="email"
              placeholder="Email"
              className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>

          <div className="mb-[22px] relative">
            <Field
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Contraseña"
              className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none pr-10"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-500"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
            <ErrorMessage
              name="password"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>

          <div className="mb-9">
            <button
              type="submit"
              className="w-full px-5 py-3 text-base text-white transition duration-300 ease-in-out border rounded-md cursor-pointer border-primary bg-primary hover:bg-blue-dark"
              disabled={isSubmitting}
            >
              {isLoading ? <SimpleLoading /> : "Aplicar"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InviteCompanyForm;

export interface InviteCompanyFormForm {
  nombre: string;
  apellido: string;
  telefono: string;
  email: string;
  password: string;
}

interface InviteCompanyProps {
  onSubmit: (value: InviteCompanyFormForm) => void;
}
