import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { RouteConfigProps } from "../../routeConfig/routeConfigInterface";

// Define the initial state using that type
const initialState: RouteConfigProps[] = [{
    component: undefined,
    index: undefined,
    path: undefined,
    componentLoading: undefined
}];
export const routerConfigSlice = createSlice({
  name: "routerConfig",
  initialState,
  reducers: {
    setRouterConfig: (state, action: PayloadAction<RouteConfigProps[]>) => {
        if(action.payload !== null && action.payload)
            {
                for (let index = 0; index < action.payload.length; index++) {
                    const element = action.payload[index];
                    if(state[0].component === undefined){
                        state[0].index = element.index;
                        state[0].component = element.component;
                        state[0].path = element.path;
                        state[0].componentLoading = element.componentLoading;
                    }else{
                        state.push(element)
                    }
                }
    
            }
    }
  },
});
export const { setRouterConfig } = routerConfigSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const GetRouterConfigSelector = (state: RootState) => state.routerConfig;
export default routerConfigSlice.reducer;