import React from "react";
import { Common } from "../../../shared/common";
import { GearSvgService } from "../../../shared/IconSvg/Gear/GearSvgService";
import GearThreeWithAnimationSvg from "../../../shared/IconSvg/Gear/GearThreeWithAnimationSvg";

const Hero: React.FC = () => {
  return (
    <div
      id="home"
      className="relative overflow-hidden bg-primary pt-[120px] md:pt-[130px] lg:pt-[160px]"
    >
      <div className="container">
        <div className="-mx-4 flex flex-wrap items-center">
          <div className="w-full px-4 grid lg:grid-cols-2 grid-cols-1 items-center">

            <div
              className="hero-content wow fadeInUp mx-auto max-w-[780px] text-center"
              data-wow-delay=".2s"
            >
              <h1 className=" text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug lg:text-5xl lg:leading-[1.2]">
                AUDITRIX
              </h1>
              <p className="mx-auto mb-9 max-w-[600px] text-base font-semibold text-white sm:text-lg sm:leading-[1.44]">
              ¿A cuánto estamos de nuestra mejor versión?
              </p>
            </div>

            <div>
              <GearThreeWithAnimationSvg
                teethsBienestar={GearSvgService.GetTeehFromGear(Common.GearNames.Bienestar)}
                teethsLiderazgo={GearSvgService.GetTeehFromGear(Common.GearNames.Liderazgo)}
                teethsOperaciones={GearSvgService.GetTeehFromGear(Common.GearNames.Operaciones)}
                isBreaking={false}
              />
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
