import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { GetUserInfoResponseDto } from "shared/services/usuario/Contracts";

const initialState: GetUserInfoResponseDto = {
    company: undefined,
    user: undefined,
    permissions: undefined
};

export const UserInfoSlice = createSlice({
  name: "UserInfoSlice",
  initialState,
  reducers: {
    SetUserInfo: (state, action: PayloadAction<GetUserInfoResponseDto | undefined>) => {
        if(action.payload !== null && action.payload)
        {
            state.company = action.payload.company;
            state.user = action.payload.user;
            state.permissions = action.payload.permissions;
        }
    }
  },
});
export const { SetUserInfo } = UserInfoSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const GetUserInfoSelector = (state: RootState) => state.UserInfoSlice;
export default UserInfoSlice.reducer;