import ApiService from "../../apiService/api";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { CrearDienteObservacionRequestDto } from "./Contracts";

const urlBase = "dienteObservacion";
const endpoints = {
    Create: 'Create'
}

const Create = async (data: CrearDienteObservacionRequestDto) : Promise<BaseResponse<number>| undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.Create;
    var response = await ApiService.post<number>(finalUrl, null, data)
    return response;
}

const GetUrlComplete = {
    Create: `${urlBase}/${endpoints.Create}`.toLowerCase()
}

export const DienteObservacionService = {
    Create,
    GetUrlComplete
}