const QuestionListSkeletonLoading = () =>{

    return(
        <ul className="space-y-1.5 animate-pulse">
          <div className="bg-gray-200 rounded w-64 h-14 p-3 m-auto mb-4"></div>
          <div className="bg-gray-200 rounded w-[32rem] h-6 p-3"></div>
          <div className="bg-gray-200 rounded w-[28rem] h-6 p-3 !mt-4"></div>
          
          <div className="bg-gray-200 rounded w-[50rem] h-6 p-3 !mt-10"></div>
          <div className="bg-gray-200 rounded w-[45rem] h-6 p-3 !mt-5"></div>
          <div className="bg-gray-200 rounded w-[32rem] h-6 p-3 !mt-5"></div>
          <div className="bg-gray-200 rounded w-[50rem] h-6 p-3 !mt-10"></div>
          <div className="bg-gray-200 rounded w-[45rem] h-6 p-3 !mt-5"></div>
          <div className="bg-gray-200 rounded w-[50rem] h-6 p-3 !mt-5"></div>
        </ul>
    )
}

export default QuestionListSkeletonLoading;