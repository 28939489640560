
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';

  const validationSchema = Yup.object({
    name: Yup.string().required('Nombre es requerido'),
    lastName: Yup.string().required('Apellido es requerido'),
    email: Yup.string().email('Email inválido').required('Email es requerido'),
    phone: Yup.string().required('Teléfono es requerido'),
    message: Yup.string().required('Mensaje es requerido')
  });

const HomeForm = ({onSubmit} : HomeFormProps) =>{

  const initialValues: HomeFormValues = {
    name: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  };

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
            resetForm();
            setSubmitting(false);
            onSubmit(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="w-full max-w-md  bg-white rounded">
            <div className="mb-[22px]">
                <label
                      htmlFor="name"
                      className="mb-4 block  text-body-color dark:text-dark-6 text-start text-base"
                    >
                      Nombre*
                    </label>
              <Field
                type="text"
                name="name"
                placeholder="Carlos"
                className="w-full border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6"
              />
              <ErrorMessage name="name" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="mb-[22px]">
                <label
                      htmlFor="lastName"
                      className="mb-4 block text-base text-body-color dark:text-dark-6 text-start"
                    >
                      Apellido*
                    </label>
              <Field
                type="text"
                name="lastName"
                placeholder="Lopez"
                className="w-full border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6"
              />
              <ErrorMessage name="lastName" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="mb-[22px]">
                <label
                        htmlFor="email"
                        className="mb-4 block text-base text-body-color dark:text-dark-6 text-start"
                        >
                        Email*
                        </label>
              <Field
                type="email"
                name="email"
                placeholder="carlos.lopez@auditrix.com"
                className="w-full border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6"
              />
              <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="mb-[22px]">
                <label
                        htmlFor="telefono"
                        className="mb-4 block text-base text-body-color dark:text-dark-6 text-start"
                        >
                        Teléfono*
                        </label>
              <Field
                type="text"
                name="phone"
                placeholder="+54 123 456789"
                className="w-full border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6"
              />
              <ErrorMessage name="phone" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            
            <div className="mb-[22px]">
            <label
                      htmlFor="mensaje"
                      className="mb-4 block text-base text-body-color dark:text-dark-6 text-start"
                    >
                      Mensaje*
                    </label>
              <Field
                as="textarea"
                type="text"
                name="message"
                placeholder="Escriba su mensaje aquí"
                className="w-full resize-none border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6"
              />
              <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
            </div>
            

            

            <div className="mb-9">
              <button
                type="submit"
                className="w-full px-5 py-3 text-base text-white transition duration-300 ease-in-out border rounded-md cursor-pointer border-primary bg-primary hover:bg-blue-dark"
                disabled={isSubmitting}
              >
                Aplicar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    )
}


export default HomeForm;

export interface HomeFormValues {
    name: string;
    lastName: string;
    email: string;
    phone: string;
    message: string;
  }

interface HomeFormProps{
    onSubmit: (value: HomeFormValues) => void;
}
  