import axios from "axios";
import axiosRetry from "axios-retry";
import { addPending, deletePending } from "../store/slices/loadingConnectionSlice";
import { store } from "../store/store";
import { BaseResponse } from "../wrappers/BaseResponse";
import { handleNotification } from "./handleNotification";

const apiUrl = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: `${apiUrl}/api/`,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json; charset=utf-8"
  },
});


axiosRetry(api, {
  retries: 10, // Número de reintentos
  retryDelay: (retryCount) => {
    return retryCount * 2000; // Retraso entre reintentos (1 segundo por intento)
  },
  retryCondition: (error) => {
    // Condición para reintentar (por ejemplo, cuando no hay respuesta)
    return error.response === undefined 
    || error.code === 'ECONNABORTED' 
    || error.code ==='ERR_NAME_NOT_RESOLVED';
  }
});

api.interceptors.request.use(
  (config) => {
    let pendingName = config.url;

    if(pendingName?.includes("?"))
      pendingName = pendingName.split("?")[0];

    store.dispatch(addPending(pendingName!));
    var token = store.getState().token.accessToken;
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    let pendingName = error.config.url;
    if(pendingName?.includes("?"))
      pendingName = pendingName.split("?")[0];
    store.dispatch(deletePending(pendingName!));
  }
);
api.interceptors.response.use(
  (response) => {
    // Código que se ejecuta después de recibir la respuesta
    let pendingName = response.config.url;
    if(pendingName?.includes("?"))
      pendingName = pendingName.split("?")[0];
    store.dispatch(deletePending(pendingName!));

    return response;
  },
  (error) => {
    let pendingName = error.config.url;
    if(pendingName?.includes("?"))
      pendingName = pendingName.split("?")[0];
    store.dispatch(deletePending(pendingName!));

    // modalService.ErrorConnection()
    // toastService.error("Hubo un error al consultar el servidor."); 
   }
);
const post = async <T> (url: string, params: any = undefined, data?: any, showNotification?: boolean): Promise<BaseResponse<T> | undefined> => {
  let apiResponse: BaseResponse<T> | undefined;
  if (params) url += getparams(params);
  await api
    .post(url, data)
    .then((response) => {
      apiResponse = response.data;
    })
    .catch((err) => {
      apiResponse = undefined;
    });
    
  if(showNotification){
    handleNotification(apiResponse)
  }
  
  return apiResponse;
};
const put = async <T> (url: string, params: any = undefined, data?: any, showNotification?: boolean) => {
  let apiResponse: BaseResponse<T> | undefined;
  if (params) url += getparams(params);
  await api
    .put(url, data)
    .then((response) => {
      apiResponse = response.data;
    })
    .catch((err) => {
      apiResponse = undefined;
    });

    if(showNotification){
      handleNotification(apiResponse)
    }
  return apiResponse;
};
const patch = async <T> (url: string, params: any = undefined, data?: any, showNotification?: boolean) => {
  let apiResponse: BaseResponse<T> | undefined;
  if (params) url += getparams(params);
  await api
    .patch(url, data)
    .then((response) => {
      apiResponse = response.data;
    })
    .catch((err) => {
      apiResponse = undefined;
    });
  if(showNotification){
    handleNotification(apiResponse)
  }
  return apiResponse;
};
const get = async <T> (url: string, params: any = undefined, data?: any, showNotification?: boolean): Promise<BaseResponse<T> | undefined> => {
  let apiResponse: BaseResponse<T> | undefined;
  if (params) url += getparams(params);
  await api
    .get(url, data)
    .then((response) => {
      apiResponse = response.data;
    })
    .catch((err) => {
      apiResponse = undefined;
    })
    .finally(() =>{
      var pendingName = url;
      if(pendingName?.includes("?"))
        pendingName = pendingName.split("?")[0];
      store.dispatch(deletePending(pendingName!));
    })

  if(showNotification){
    handleNotification(apiResponse)
  }

  return apiResponse;
};
const remove = async <T> (url: string, params: any = undefined, data?: any, showNotification?: boolean) => {
  let apiResponse: BaseResponse<T> | undefined;
  if (params) url += getparams(params);
  await api
    .delete(url, data)
    .then((response) => {
      apiResponse = response.data;
    })
    .catch((err) => {
      apiResponse = undefined;
    });
  if(showNotification){
    handleNotification(apiResponse)
  }
  return apiResponse;
};
const getparams = (params: any) => {
  let paramsParsed = "";

  Object.keys(params).forEach((key) => {
    if (paramsParsed.length === 0 && params[key] != null && params[key] != undefined ) 
      paramsParsed = `?${key}=${params[key]}`;
    else if(params[key] != null && params[key] != undefined )
      paramsParsed += `&${key}=${params[key]}`;
  });

  return paramsParsed;
};
const ApiService = {
  post,
  get,
  put,
  patch,
  remove,
};
export default ApiService;