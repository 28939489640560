import { BaseResponse } from "../../../shared/wrappers/BaseResponse";
import ApiService from "../../apiService/api";
import { GetGearResultsByUserIdResponseDto } from "./Contracts";

const urlBase = "engranaje";
const endpoints = {
    GetGearResultsByUserId: 'GetGearResultsByUserId'
}

const GetGearResultsByUserId = async () : Promise<BaseResponse<GetGearResultsByUserIdResponseDto[]> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetGearResultsByUserId;
    var response = await ApiService.get<GetGearResultsByUserIdResponseDto[]>(finalUrl, null, null, true)

    return response;
}

const GetUrlComplete = {
    GetGearWithRelation: `${urlBase}/${endpoints.GetGearResultsByUserId}`.toLowerCase()
}

export const GearService = {
    GetGearResultsByUserId,
    GetUrlComplete
}