import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ButtonPrimary from "../../../shared/components/button/ButtonPrimary";
import { CrearDienteObservacionRequestDto } from "../../../shared/services/dienteObservacion/Contracts";
import { useAppSelector } from "../../../shared/store/hooks";
import { currentQuestion } from "../../../shared/store/slices/question/currentQuestionSlice";
import { DienteObservacionService } from "../../../shared/services/dienteObservacion/DienteObservacionService";
import { toastService } from "../../../shared/components/toast/toastService";
import { getCurrentGearSelector } from "../../../shared/store/slices/gear/currentGearSlice";
import { useEffect, useState } from "react";
import { GetUserInfoSelector } from "../../../shared/store/slices/user/UserInfoSlice";

const validationSchema = Yup.object({
  observation: Yup.string().required("Campo requerido"),
});

const observatonSend = "Tu observación fue enviada.";

const QuestionObservation = () => {
  const currentQuestionSelector = useAppSelector(currentQuestion);
  const currentTeethSelector = useAppSelector(getCurrentGearSelector);
  const userInfo = useAppSelector(GetUserInfoSelector);
  const initialValues = {
    observation: "",
  };
  const [currentTeeth, setCurrentTeeth] = useState<string>();
  const [currentGear, setCurrentGear] = useState<string>();

  const handleSubmit = async (value: typeof initialValues) =>{
    var data : CrearDienteObservacionRequestDto = {
      dienteGeneralId: currentQuestionSelector.diente_Tipo_Id,
      observacion: value.observation,
      preguntaId: currentQuestionSelector.id
    }

    var response = await DienteObservacionService.Create(data);
  
    if(response && !response.hasError){
      toastService.success(observatonSend);
    }
  }

  const handleSetVariables = () =>{

    var teethName = currentTeethSelector.dientes.find(x => x.id === currentQuestionSelector.diente_Tipo_Id)?.name;
    var gearName = currentTeethSelector.name;
    console.log(gearName, "gearName")


    if(gearName){
      setCurrentGear(gearName)
      console.log(gearName, "gearName")

    }

    if(!teethName)
    {
      setCurrentTeeth("Diente")
      return;
    }

    setCurrentTeeth(teethName)


  }

  useEffect(() =>{
    handleSetVariables();
  }, [])

  return (
    <>
      {/* <h1 className="text-xl mb-2">¿Deseas agregar alguna observación sobre {currentTeeth} de {userInfo.company?.name}?</h1> */}
      {currentGear === "Liderazgo" ?
        (
          
          <h1 className="text-xl mb-2">Agrega más observaciones respecto a {currentTeeth} de tu superior?</h1>
        )
        :
        (
          <h1 className="text-xl mb-2">Agrega más observaciones sobre {currentTeeth} de {userInfo.company?.name}?</h1>

        )
      }

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          resetForm();
          setSubmitting(false);
          handleSubmit(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="w-full px-8 pt-8 bg-white rounded">
            <div className="mb-[22px]">
              <Field
                as="textarea"
                id="observation"
                name="observation"
                placeholder="Observacion..."
                className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
                rows={4}
              />

              <ErrorMessage
                name="observation"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>


            <div className="mb-9 flex justify-end pb-3">
              <ButtonPrimary
                type="submit"
                disabled={isSubmitting}
              >
                Enviar observacion
              </ButtonPrimary>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default QuestionObservation;
