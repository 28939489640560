import { GetEmpresaInfoContractResponse } from "../cantidadEmpleados/Contract";
import ApiService from "../../apiService/api";
import { BaseResponse } from "../../wrappers/BaseResponse";

const urlBase = "tipoorganizacion";
const endpoints = {
    GetTipoOrganizacion: 'GetAll'
}

const GetTipoOrganizacion = async () : Promise<BaseResponse<GetEmpresaInfoContractResponse[]> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetTipoOrganizacion;
    var response = await ApiService.get<GetEmpresaInfoContractResponse[]>(finalUrl)

    return response;
}

const GetUrlComplete = {
    GetEmpresaInfo: `${urlBase}/${endpoints.GetTipoOrganizacion}`.toLowerCase()
}

export const OrganizationTypeService = {
    GetTipoOrganizacion,
    GetUrlComplete
}