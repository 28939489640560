import { useEffect, useState } from "react";
import AccordeonGeneric from "../../../../shared/components/accordeon/AccordeonGeneric";
import GearSvg, { teeth } from "../../../../shared/IconSvg/Gear/GearSvg";
import { EmpresaDientesGearResultResponse } from "../../../../shared/services/empresa/Contracts";
import {
  DientesGearResultResponse,
  GetGearResultsByUserIdResponseDto,
} from "../../../../shared/services/engranaje/Contracts";
import { GearService } from "../../../../shared/services/engranaje/GearService";
import { useAppSelector } from "../../../../shared/store/hooks";
import { connectionPendings } from "../../../../shared/store/slices/loadingConnectionSlice";

const QuestionResultIndividual = () => {
  const loadingConnection = useAppSelector(connectionPendings);
  const [gearsByUser, setGearsByUser] = useState<
    GetGearResultsByUserIdResponseDto[] | undefined
  >();

  const getGearsByUser = async () => {
    var gears = await GearService.GetGearResultsByUserId();
    console.log(gears, "gears");

    setGearsByUser(gears?.data);
  };

  useEffect(() => {
    getGearsByUser();
  }, []);

  const handleSetTeethAmount = (
    dientes:
      | DientesGearResultResponse[]
      | EmpresaDientesGearResultResponse[]
      | undefined
  ): teeth[] => {
    var teeths: teeth[] = [];

    if (dientes) {
      teeths = dientes.map((diente, index) => {
        var teeth: teeth = {
          diente: index + 1,
          porcentaje: diente.porcentaje,
        };

        return teeth;
      });
    }

    return teeths;
  };

  return !loadingConnection.includes(
    GearService.GetUrlComplete.GetGearWithRelation
  ) ? (
    <>
      <h3>Resultados individuales</h3>

      {gearsByUser?.map((engranaje) => (
        <div className="mb-5" key={engranaje.id}>
          <AccordeonGeneric
            key={engranaje.id}
            withoutIcon
            title={
              <h1 className="uppercase font-bold text-white">
                {engranaje.nombre}
              </h1>
            }
            className="!bg-page"
          >
            <div className="flex flex-col md:flex-row rounded-b-lg justify-between py-4">
  <div className="rounded-b-lg pl-4 w-full md:w-[40rem] h-min mb-4 md:mb-0">
    {engranaje?.dientes?.map((diente) => (
      <div
        key={diente.nombre}
        className="flex justify-between items-center border-b p-2"
      >
        <p>{diente.nombre}</p>
        <p className="font-bold">{diente.porcentaje}%</p>
      </div>
    ))}
  </div>

  <div className="flex justify-center md:justify-start w-full">
    <div className="flex flex-col items-center">
      <GearSvg
        className="w-[20rem] h-[20rem] animate-spin-wobble"
        teethAmount={handleSetTeethAmount(engranaje.dientes)}
        text={engranaje.nombre}
        textClassName="font-bold"
      />
      <p className="text-[#2596aa] text-2xl font-bold">
        {engranaje?.porcentaje ?? 0}%
      </p>
    </div>
  </div>
</div>

          </AccordeonGeneric>
        </div>
      ))}
    </>
  ) : (
    <>
      <ul className="space-y-1.5 animate-pulse">
        <div className="bg-gray-200 rounded w-[80%] h-20 p-3 m-auto mb-4"></div>
        <div className="bg-gray-200 rounded w-[80%] h-20 p-3 m-auto !mb-4"></div>
        <div className="bg-gray-200 rounded w-[80%] h-20 p-3 m-auto mb-4"></div>
      </ul>
    </>
  );
};

export default QuestionResultIndividual;
