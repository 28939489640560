import { useAuth0 } from "@auth0/auth0-react";
import Sidebar from "../../shared/layout/sideBar/Sidebar";
import { useAppSelector } from "../../shared/store/hooks";
import { GetTokenSelector } from "../../shared/store/slices/tokenSlice";
import QuestionComponent from "./components/QuestionComponent";
import { ReactElement, useEffect, useState } from "react";
import { getCurrentComponentQuestion } from "../../shared/store/slices/question/changeComponentQuestionSlice";
import QuestionComponentsService from "./QuestionComponentsService";
import QuestionResults from "./components/QuestionResults/QuestionResults";
import QuestionHome from "./components/QuestionHome";
import PageLoading from "../../shared/IconSvg/Spinner/PageLoading";
import { connectionPendings } from "../../shared/store/slices/loadingConnectionSlice";
import { QuestionService } from "../../shared/services/question/QuestionService";
import SimpleLoading from "../../shared/IconSvg/Spinner/SimpleLoading";

const Question = () => {
  const getToken = useAppSelector(GetTokenSelector);
  const currentComponent = useAppSelector(getCurrentComponentQuestion);
  const { isLoading } = useAuth0();
  const [seeComponent, setSeeComponent] = useState<ReactElement>();
  const [questionLoading, setQuestionLoading] = useState<boolean>(false);
  const loadingConnection = useAppSelector(connectionPendings);

  // Métodos
  const handleChangeComponent = () => {
    switch (currentComponent) {
      case QuestionComponentsService.questions:
        setSeeComponent(<QuestionComponent />);
        break;
      case QuestionComponentsService.results:
        setSeeComponent(<QuestionResults />);
        break;
      default:
        setSeeComponent(<QuestionHome />);
        break;
    }
  };

  // Efectos
  useEffect(() => {
    handleChangeComponent();
  }, [currentComponent]);

  useEffect(() => {
    setQuestionLoading(
      loadingConnection.includes(
        QuestionService.GetUrlComplete.GetQuestionByTeethId
      )
    );
  }, [loadingConnection]);

  if (!getToken.accessToken && isLoading) {
    // Cargando datos...
    return <PageLoading />;
  } else if (!getToken.accessToken && !isLoading) {
    // Sin permisos
    return <h1>Sin permisos</h1>;
  }

  return (
    <div className="bg-[#E0E0E0] min-h-screen relative lg:ml-[16.3rem] mt-14 lg:mt-auto p-5">
      <Sidebar />
      <div className="pt-20 sm:pt-4 px-4 pb-4 bg-slate-100 rounded-md xl:max-w-[70rem] lg:max-w-[40rem] m-auto min-h-[18rem] flex flex-col justify-between relative">
        {questionLoading ? <SimpleLoading /> : seeComponent}
      </div>
    </div>
  );
};

export default Question;
