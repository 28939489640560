import { useEffect, useState } from "react";
import GearOrganizationIcon from "../../../shared/IconSvg/GearOrganization/GearOrganizationIcon";
import { ResolveQuestionRequest } from "../../../shared/services/dienteResultado/Contracts";
import { TeethResolveService } from "../../../shared/services/dienteResultado/TeethResolveService";
import { QuestionService } from "../../../shared/services/question/QuestionService";
import { useAppSelector } from "../../../shared/store/hooks";
import { getCurrentGearSelector } from "../../../shared/store/slices/gear/currentGearSlice";
import { gearlAll } from "../../../shared/store/slices/gear/gearAllSlice";
import { currentQuestion, setCurrentQuestion } from "../../../shared/store/slices/question/currentQuestionSlice";
import QuestionList from "../components/QuestionList";
import { connectionPendings } from "../../../shared/store/slices/loadingConnectionSlice";
import QuestionListSkeletonLoading from "./QuestionListSkeletonLoading";
import { GetQuestionByTeethIdContract, GetQuestionByTeethIdRespuestasContract } from "../../../shared/services/question/Contracts";
import { store } from "../../../shared/store/store";


const QuestionComponent = () => {
  //Estados
  const defaultRequestQuestion = {
    id: 1,
    page: 1,
  };
  const [currentTeethName, setCurrentTeethName] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  
  const [questionLoading, setQuestionLoading] = useState<boolean>(false);
  //Selectores
  const getCurrentQuestion = useAppSelector(currentQuestion);
  const getCurrentGear = useAppSelector(getCurrentGearSelector);
  const loadingConnection = useAppSelector(connectionPendings);
  const currentGearSelector = useAppSelector(getCurrentGearSelector);
  //Metodos
  const getCurrentQuestionEndpoint = async (
    id: number = defaultRequestQuestion.id,
    page: number = defaultRequestQuestion.page
  ) => {
    await QuestionService.GetQuestionByTeethId(id, page);
  };

  const handleResolveAnswer = async (answerId: number, notApply: boolean) => {
    var data: ResolveQuestionRequest = {
      diente_Id: getCurrentQuestion.diente_Tipo_Id,
      respuesta_Id: answerId,
      question_Id: getCurrentQuestion.id,
      not_Apply: notApply
    };

    //Guardo pregunta
    await TeethResolveService.ResolverPregunta(data);
    //Actualizo respuestas
    console.log(getCurrentQuestion.preguntasResueltas, "eaeaea")
    handleUpdateAnswer(answerId);
  };

  const handleUpdateAnswer = (answerId: number) =>{
    let newCurrentQuestions : GetQuestionByTeethIdContract = {
      contenido: getCurrentQuestion.contenido,
      diente_Tipo_Id: getCurrentQuestion.diente_Tipo_Id,
      id: getCurrentQuestion.id,
      paginaActual: getCurrentQuestion.paginaActual,
      paginasTotales: getCurrentQuestion.paginasTotales,
      porcentaje: getCurrentQuestion.porcentaje,
      respuestas: [...getCurrentQuestion.respuestas],
      preguntasResueltas: [...getCurrentQuestion.preguntasResueltas]
    } 

    newCurrentQuestions.respuestas = newCurrentQuestions.respuestas.map(x =>{  

        var respuesta : GetQuestionByTeethIdRespuestasContract ={
          contenido: '',
          id: 0,
          porcentaje: 0,
          respondida: false
        };

        if(x.id === answerId){  
          respuesta.contenido = x.contenido;
          respuesta.id = x.id;
          respuesta.porcentaje = x.porcentaje;
          respuesta.respondida = true;
      }
        else{
          respuesta.contenido = x.contenido
          respuesta.id = x.id;
          respuesta.porcentaje = x.porcentaje;
          respuesta.respondida = false;
        }

        return respuesta;
    })

    if(!newCurrentQuestions.preguntasResueltas.includes(currentPage)){
      newCurrentQuestions.preguntasResueltas.push(currentPage);
    }

    
    

    store.dispatch(setCurrentQuestion(newCurrentQuestions))
  }

  useEffect(() => {
    if (getCurrentQuestion && getCurrentQuestion.diente_Tipo_Id) {
      setCurrentPage(getCurrentQuestion.paginaActual);
    }
  }, [getCurrentQuestion]);

  useEffect(() => {
    if (currentGearSelector && currentGearSelector.dientes && currentGearSelector.dientes.length > 0){
      var teethName = currentGearSelector.dientes.find(x => x.id === getCurrentQuestion.diente_Tipo_Id)?.name;
      setCurrentTeethName(teethName)
    }
  }, []);

  useEffect(() => {
    var loadingTeeth = loadingConnection.includes(TeethResolveService.GetUrlComplete.ResolverPregunta);
    var loadingQuestion = loadingConnection.includes(QuestionService.GetUrlComplete.GetQuestionByTeethId);
    var isLoading = !loadingTeeth && loadingQuestion;

    setQuestionLoading(isLoading)
  }, [loadingConnection]);




  return (
    <>
      {questionLoading ? (

          <QuestionListSkeletonLoading/>

      ) : getCurrentQuestion?.contenido ? (
        <div>
          <h1 className="uppercase text-4xl font-bold text-center mb-4">
            {getCurrentGear?.name ? getCurrentGear.name : "Sin contenido"}
          </h1>
          <h2 className="text-2xl font-semibold mb-6">{currentTeethName}</h2>
          <QuestionList
            currentQuestion={getCurrentQuestion}
            onResolveAnswer={(answer, notApply) => handleResolveAnswer(answer,notApply)}
            onChangePage={(page) =>
              getCurrentQuestionEndpoint(
                getCurrentQuestion.diente_Tipo_Id,
                page
              )
            }
          />
        </div>
      ) : (
        <h1>Sin contenido</h1>
      )}
    </>
  );
};

export default QuestionComponent;
