import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";

const titleDefault = "Integrity";

const PageTitle = ({ title, children }: TitleProps) => {
    const location = useLocation();

  useEffect(() => {
    document.title = title ?? titleDefault;
  }, [location]);

  return <>{children}</>;
};

export default PageTitle;

interface TitleProps {
  title?: string;
  children: ReactElement | undefined;
}
