import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Common } from "../../shared/common";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import InviteCompanyForm, { InviteCompanyFormForm } from "./components/InviteCompanyForm";
import { toastService } from "../../shared/components/toast/toastService";
import { modalService } from "../../shared/components/modal/ModalService";
import { UsuarioService } from "../../shared/services/usuario/UsuarioService";
import { UsuarioCreateRequestDto } from "../../shared/services/usuario/Contracts";

const userRegisteredSuccess = "Usuario registrado correctamente"

const InviteCompany = () => {
  const [companyId, setCompanyId] = useState<number | undefined>(undefined);
  const [role, setRole] = useState<string | undefined>(undefined);
  const navigate = useNavigate();


  const handleGetIdEncrypt = () : string =>{
    const params = new URLSearchParams(window.location.search);
    const rawId = params.get('id');
    let id = '';

    if (rawId) {
      const decodedId = decodeURIComponent(rawId).replace(/\s/g, '+');
      id = decodedId;
    }

    return id;

  };

  const handleGetRoleEncrypt = () : string =>{
    const params = new URLSearchParams(window.location.search);
    const rawRole = params.get('role');
    let role = '';

    if (rawRole) {
      const decodedId = decodeURIComponent(rawRole).replace(/\s/g, '+');
      role = decodedId;
    }

    return role;

  };

  const handleRedirect = () => {
    navigate("/");
  };

  const handleDecryptId = () => {
    const id = handleGetIdEncrypt();
    const role = handleGetRoleEncrypt();

    //Si no manda ID
    if (id === '' || !id) {
      //Lo mandamos pal lobby.
      handleRedirect();
      return;
    }

    var number = Common.DecryptNumber(id);
    var roleDecrypt = Common.DecryptNumber(role);

    //Si la key es invalida
    if (!number) {
      //Lo mandamos pal lobby.
      handleRedirect();
      return;
    }

    setCompanyId(Number(number));
    setRole(roleDecrypt);

  };

  const handleSubmit = async (values: InviteCompanyFormForm) => {

    var data : UsuarioCreateRequestDto = {
      apellido: values.apellido,
      email: values.email,
      nombre: values.nombre,
      password: values.password,
      telefono: values.telefono,
      empresaId: companyId,
      role

    };

    var result = await UsuarioService.Crear(data)

    if(result && !result?.hasError){
      handleRedirect();
      toastService.success(userRegisteredSuccess);
  
      modalService.InviteCompanyUserCreated();
    }
  };

  useEffect(() => {
    handleDecryptId();
  }, []);

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-page">
        <h1 className="text-3xl mb-2 text-white">Formulario de invitacion</h1>
        <InviteCompanyForm onSubmit={value => handleSubmit(value)}/>
    </div>
  );
};

export default InviteCompany;
