import CachedIcon from "@mui/icons-material/Cached";
import { useEffect, useState } from "react";
import AccordeonGeneric from "../../../../shared/components/accordeon/AccordeonGeneric";
import GearSvg from "../../../../shared/IconSvg/Gear/GearSvg";
import GearThreeWithAnimationSvg from "../../../../shared/IconSvg/Gear/GearThreeWithAnimationSvg";
import {
  GetResultsByCompanyIdResponseDto
} from "../../../../shared/services/empresa/Contracts";
import { EmpresaService } from "../../../../shared/services/empresa/EmpresaService";
import { useAppSelector } from "../../../../shared/store/hooks";
import { connectionPendings } from "../../../../shared/store/slices/loadingConnectionSlice";
import { GearSvgService } from "../../../../shared/IconSvg/Gear/GearSvgService";

const QuestionResultGlobal = () => {
  const loadingConnection = useAppSelector(connectionPendings);
  const [getCompanyResultByUser, setCompanyResultByUser] = useState<
    GetResultsByCompanyIdResponseDto[] | undefined
  >();
  const [porcentajePromedio, setPorcentajePromedio] = useState(0);


  const handleGetCompanyResultByUser = async () => {
    var results = await EmpresaService.GetResultsByCompanyId();
    
    if (results?.data) {
      const totalPercentage = results.data.reduce((sum, item) => sum + item.porcentaje!, 0);
      const averagePercentage = totalPercentage / 3;
      // Puedes almacenar el promedio en el estado si lo necesitas
      setPorcentajePromedio(Number(averagePercentage.toFixed(2)));
      console.log(averagePercentage, "averagePercentage")
    }

    setCompanyResultByUser(results?.data);
  };

  useEffect(() => {
    handleGetCompanyResultByUser();
  }, []);

  return !loadingConnection.includes(
    EmpresaService.GetUrlComplete.GetResultsByCompanyId
  ) ? (
    <>
      <h3>
        Resultados globales
        <p
          onClick={() => handleGetCompanyResultByUser()}
          className="ml-1 inline cursor-pointer"
          title="Recargar"
        >
          <CachedIcon />
        </p>
      </h3>

      {getCompanyResultByUser?.map((engranaje) => (
        <div key={engranaje.id}>
          <AccordeonGeneric
            key={engranaje.id}
            withoutIcon
            title={
              <h1 className="uppercase font-bold text-white">
                {engranaje.nombre}
              </h1>
            }
            className="!bg-page"
          >
            <div className="flex rounded-b-lg justify-between py-4">
              <div className="rounded-b-lg pl-4 w-[40rem] h-min">
                {engranaje?.dientes?.map((diente) => (
                  <div
                    key={diente.nombre}
                    className="flex justify-between item-center border-b p-2"
                  >
                    <p>{diente.nombre}</p>

                    <p className="font-bold">{diente.porcentaje}%</p>
                  </div>
                ))}
              </div>

              <div className="m-auto">
                <div className="flex flex-col items-center">
                  <GearSvg
                    className="w-[20rem] h-[20rem] animate-spin-wobble"
                    teethAmount={GearSvgService.HandleSetTeethAmount(engranaje.dientes)}
                    text={engranaje.nombre}
                    textClassName="font-bold"
                  />

                  <p className="text-[#2596aa] text-2xl font-bold">
                    {engranaje?.porcentaje ?? 0}%
                  </p>
                </div>
              </div>
            </div>
          </AccordeonGeneric>
        </div>
      ))}

      {getCompanyResultByUser && (
        <div>
          <h1 className="uppercase text-2xl font-bold mt-10 text-center">
            Integridad sistemica
          </h1>
          <h3 className="text-center text-2xl font-bold">{porcentajePromedio}%</h3>

          <GearThreeWithAnimationSvg 
            teethsBienestar={GearSvgService.HandleSetTeethAmount(
              getCompanyResultByUser![1].dientes
            )}
            teethsLiderazgo={GearSvgService.HandleSetTeethAmount(
              getCompanyResultByUser![2].dientes
            )}
            teethsOperaciones={GearSvgService.HandleSetTeethAmount(
              getCompanyResultByUser![0].dientes
            )}
            isBreaking
          />
        </div>
      )}
    </>
  ) : (
    <>
      <ul className="space-y-1.5 animate-pulse mt-10">
        <div className="bg-gray-200 rounded w-[80%] h-20 p-3 m-auto mb-4"></div>
        <div className="bg-gray-200 rounded w-[80%] h-20 p-3 m-auto !mb-4"></div>
        <div className="bg-gray-200 rounded w-[80%] h-20 p-3 m-auto mb-4"></div>
      </ul>
    </>
  );
};

export default QuestionResultGlobal;
