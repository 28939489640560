const GearSkeletonLoading = () =>{

    const gears = [1,2,3];

    return(
        <>
        {
        gears.map((e) => (
            <ul key={e} className="space-y-1.5 animate-pulse">
              <div className="bg-gray-200 rounded w-full h-14"></div>
            </ul>
          ))
          }
        </>
    )
}

export default GearSkeletonLoading;