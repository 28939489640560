import { HideModal, ShowModal } from "../../../shared/store/slices/modalSlice";
import { store } from "../../../shared/store/store";
import ErrorConnectionModal from "./components/ErrorConnectionModal";
import InviteCompanyLinkModal from "./components/InviteCompanyLinkModal";
import InviteCompanyUserCreatedModal from "./components/InviteCompanyUserCreatedModal";

const ErrorConnection = () =>{
    const name = "ErrorConnectionModal";

    store.dispatch(ShowModal(name))
}

const InviteCompanyUserCreated = () =>{
    const name = "InviteCompanyUserCreatedModal";

    store.dispatch(ShowModal(name))
}

const InviteCompanyLink = () =>{
    const name = "InviteCompanyLinkModal";

    store.dispatch(ShowModal(name))
}

const closeModal = (name: string) =>{
    store.dispatch(HideModal(name));
}


export const modalRegistered : modalRegisteredProps = {
    ErrorConnectionModal,
    InviteCompanyUserCreatedModal,
    InviteCompanyLinkModal
}

export const modalService = {
    ErrorConnection,
    InviteCompanyUserCreated,
    InviteCompanyLink,
    closeModal
}

interface modalRegisteredProps{
    [key: string]: any;
}