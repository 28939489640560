const teethColorGama ={
    red: "color-1",
    yellow: "color-1",
    green: "teeth-green",
    base1: "color-1",
    base2: "color-2"
}

const teethColorSetting = [
    {
        color: "teeth-red",
        offset: "5%",
        stopColor: "#ff0000",
        offset2: "85%",
        stopColor2: "#b81414"
    },
    {
        color: "teeth-yellow",
        offset: "5%",
        stopColor: "#642424",
        offset2: "85%",
        stopColor2: "#4b2222"
    },
    {
        color: "teeth-green",
        offset: "5%",
        stopColor: "#F9D62E",
        offset2: "85%",
        stopColor2: "#B78727"
    },
    {
        color: "color-2",
        offset: "5%",
        stopColor: "#F9D62E",
        offset2: "85%",
        stopColor2: "#B78727"
    },
    {
        color: "color-1",
        offset: "5%",
        stopColor: "#CF910A",
        offset2: "85%",
        stopColor2: "#754100"
    }
]

const teethColorSet = [
    {from: 70, to: 100, color: teethColorGama.green},
    {from: 40, to: 70, color: teethColorGama.yellow},
    {from: 0, to: 40, color: teethColorGama.red },
    {from:101, to: 110, color: teethColorGama.green}
  ];

export const teethConfig = {
    teethColorGama,
    teethColorSetting,
    teethColorSet
} 