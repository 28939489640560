import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { GetQuestionByTeethIdContract } from "../../../services/question/Contracts";
// Define the initial state using that type
const initialState: GetQuestionByTeethIdContract = {
    contenido: "",
    id: 0,
    diente_Tipo_Id: 0,
    paginaActual: 0,
    paginasTotales: 0,
    porcentaje: 0,
    respuestas: [],
    preguntasResueltas: []
};
export const currentQuestionSlice = createSlice({
  name: "currentQuestion",
  initialState,
  reducers: {
    setCurrentQuestion: (state, action: PayloadAction<GetQuestionByTeethIdContract | undefined>) => {
        if(action.payload !== null && action.payload)
        {
            state.contenido = action.payload.contenido;
            state.id = action.payload.id;
            state.paginaActual = action.payload.paginaActual;
            state.paginasTotales = action.payload.paginasTotales;
            state.porcentaje = action.payload.porcentaje;
            state.respuestas = action.payload.respuestas;
            state.diente_Tipo_Id = action.payload.diente_Tipo_Id;
            state.preguntasResueltas = action.payload.preguntasResueltas;
        }
    }
  },
});
export const { setCurrentQuestion } = currentQuestionSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const currentQuestion = (state: RootState) => state.currentQuestion;
export default currentQuestionSlice.reducer;